.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 90px 8px 114px;
  gap: 15px;
}

.root:global(.success) {
  background: var(--color-green-4);
}

.root:global(.attention) {
  background: #FEF8DD;
  color: #867E5A;
}

.root:global(.error) {
  background: #FFC8C8;
  color: #AC2828;
}

.content {
  display: flex;
  gap: 15px;
}

.title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.42;
}

.title span {
  color: var(--grey-1);
}

.description {
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.2;
}

.icon {
  flex-shrink: 0;
  line-height: 0;
  width: 28px;
  height: 36px;
}

.icon svg {
  width: 100%;
  height: 100%;
}

.buttons {
  display: flex;
  gap: 20px;
}

.button {
  font-weight: 500;
  font-size: 15px;
}

.close {
  padding: 5px;
}

.closeIcon {
  height: 16px;
  width: 16px;
}

@media (max-width: 800px) {
  .root {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .root {
    padding-left: var(--container-padding);;
  }
}
