.root {
  flex-grow: 1;
  display: flex;
}

.main {
  text-align: center;
  flex-grow: 1;
}

.side {
  flex-shrink: 0;
  width: 36%;
  padding: 20px var(--container-padding);
  background: var(--grey-5);
  background: url('/public/images/account-bg.webp');
  background-size: cover;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px var(--container-padding);
}

.logoText path {
  fill: var(--color-black-primary)
}

.lang :global(.forma-ui__lang-switch-dropdown__dropdown) span,
.lang :global(.forma-ui__lang-switch-dropdown__dropdownArrow) {
  display: none;
}

.content {
  max-width: 720px;
  padding: 0 var(--container-padding);
  margin: 0 auto;
}

.contentHead {
  margin-bottom: 20px;
}

.title {
  margin: 0;
  font-size: 1.75rem;
  line-height: 1.2;
}

.subtitle {
  margin-top: 6px;
  color: var(--grey-2);
}

.content input {
  background: none;
  border: 1px solid var(--grey-3);
}

.content input:focus {
  background: var(--color-white-primary);
}

.question {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

@media (max-width: 575px) {
  .header {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .content {
    margin-top: 18px;
  }

  .title {
    font-size: 1.42rem;
  }

  .subtitle {
    font-weight: 600;
  }

  .content :global(.forma-ui__field-container__label) {
    font-size: 16px;
    font-weight: 400;
  }

  .content input,
  .content select,
  .content textarea,
  .content :global(.rc-select-selector) {
    font-size: 16px;
  }
}
