.content {
  position: relative;
}

.content table {
  table-layout: fixed;
}

.topButtons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--body-bg);
  z-index: 10;
  gap: 12px;
}

.cellValid {
  text-align: right;
  padding-right: 0;
}

.downloadBtn,
.removeBtn {
  min-height: 36px;
  padding: 4px 16px;
  font-size: 14px;
}

.resetBtn {
  padding: 7px;
}

.resetBtnIcon {
  width: 14px;
  height: 14px;
  color: var(--color-black-primary);
}

.selected {
  padding: 0 12px;
  font-size: 14px;
  color: var(--grey-1);
}

.cellArrow {
  width: 23px;
}

.cellIcon {
  width: 40px;
  min-width: 40px;
}

.cellCategory {
  width: 208px;
}

.cellContragent {
  width: 240px;
}

.cellValid {
  width: 130px;
}

.cellButtons {
  width: 55px;
  min-width: 55px;
}

@media (max-width: 1280px) {
  .cellCategory {
    width: 180px;
  }

  .cellContragent {
    width: 180px;
  }
}

@media (max-width: 1024px) {
  .cellCategory {
    width: 120px;
  }

  .cellContragent {
    width: 140px;
  }

  .cellValid {
    width: 115px;
  }
}

@media (max-width: 575px) {
  .content table,
  .content tbody,
  .content tr,
  .content td {
    display: block;
  }
}
