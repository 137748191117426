.content {
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
}

.advantages {
  margin-top: 40px;
  text-align: center;
  color: var(--grey-2);
  line-height: 1.85;
}

.buttons {
  max-width: 440px;
  width: 100%;
  margin: 80px auto 0;
}

.prices {
  display: inline-block;
  vertical-align: top;
  margin: 0 7px;
  line-height: 1.1;
}

.oldPrice {
  color: var(--grey-2);
  text-decoration: line-through;
}

.price {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primary-color);
}
