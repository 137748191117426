.address {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.addressContent {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 1.2;
  gap: 10px;
}

.addressItem {
  width: calc(33.33% - 10px);
  color: var(--grey-2);
}

.addressItem.wide {
  width: 100%;
}

.addressLabel {
  color: var(--grey-0);
}

.addressEdit {
  flex-shrink: 0;
  margin-top: -4px;
  margin-left: auto;
}

.addresIcon {
  display: inline-block;
  line-height: 0;
  margin-right: 4px;
}

.addresIcon svg {
  width: 14px;
  height: 14px;
}

.formButtons {
  margin-top: 56px;
  justify-content: space-between;
}

.formButtons > button {
  min-width: 240px;
}

.modalContent :global(.forma-ui__fill-group__root) {
  padding: 0;
}

@media (max-width: 575px) {
  .addressItem {
    width: 100%;
  }
}