.buttons {
  flex-direction: column;
  align-items: center;
}

.cancelButton {
  width: auto;
  margin-bottom: 10px;
  text-decoration: underline;
}

.uploadButton {
  max-width: 340px;
}

.successModal {
  min-height: 362px;
  text-align: center;
}

.successTitle {
  margin-top: 24px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.1;
}

.successDescr {
  margin: 16px auto 0;
  line-height: 1.33;
}

.successButton {
  max-width: 240px;
}

.successImage {
  max-width: 100%;
}

.dropzone {
  height: 306px;
}

.dropzoneContent {
  max-width: 440px;
  margin: 0 auto;
}

.dropzoneIcon {
  vertical-align: bottom;
}

@media (max-width: 575px) {
  .cancelButton {
    flex: 1 1 100%;
  }

  .dropzone {
    height: auto;
  }

  .dropzoneContent {
    max-width: 575px;
    margin: 0;
  }
}
