.root {
  position: relative;
  height: 100%;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.rootAdd {
  background: var(--color-green-4);
  transition: background var(--transition);
}

.rootAdd .container {
  padding: 48px 10px;
}

.rootAdd .iconContainer {
  height: 37px;
}

.rootAdd .nameContainer {
  margin-top: 16px;
}

.rootAdd .name {
  color: var(--color-green-primary);
}

.container {
  height: 100%;
  padding: 18px 20px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  pointer-events: none;
}

.icon {
  height: 100%;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 2.1rem;
  font-size: 0.875rem;
  line-height: 1.21;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder {
  height: 100%;
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-b);
}

.optionsButton {
  position: absolute;
  display: inline-flex;
  justify-content: space-between;
  bottom: 5px;
  right: 7px;
  line-height: 0;
  color: var(--grey-7);
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: all var(--transition);
}

.optionsButton:hover {
  color: var(--grey-6);
}

.optionsButton.active {
  transform: rotate(90deg);
}

.iconChecked {
  line-height: 0;
  color: var(--primary-color);
  margin-left: auto;
}

.renameModal {
  margin: 0 0 26px;
}

.renameModal input {
  width: 100%;
  border-color: var(--grey-4);
}
