.root {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  gap: 8px
}

.button {
  min-height: 46px;
  padding: 8px 14px;
  font-weight: 500;
  border: 1px solid var(--grey-3);
}
