.root {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
}

.title {
  display: flex;
  align-items: center;
  height: 34px;
  margin-bottom: 16px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.2;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}