.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  flex-grow: 1;
  padding: 3px 0;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nameInput {
  flex-grow: 1;
  height: 30px;
  padding: 0 10px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button {
  flex: 0 0 auto;
  line-height: 0;
  transition: all var(--transition);
}

.toggler {
  transform: scaleY(-1);
}

.toggler.open {
  transform: scaleY(1);
}

.delete:hover,
.delete:focus-visible {
  color: var(--color-red-primary);
}

.edit:hover,
.edit:focus-visible {
  color: var(--color-green-primary);
}

.content {
  margin-top: 10px;
  padding: 6px 14px;
  border: 2px dashed var(--grey-3);
  border-radius: var(--border-radius-m);
  transition: background var(--transition);
}

.content.hover {
  background: var(--color-green-4);
  border-color: var(--color-green-light);
}

.content.hover .dropText {
  color: var(--color-green-primary);
}

.dropText {
  display: block;
  padding: 12px 0;
  font-weight: 600;
  color: var(--grey-2);
  text-align: center;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.sidesCount {
  font-weight: 600;
  padding: 7px;
  border-radius: var(--border-radius-l);
}

.sidesCount.fill {
  background: var(--color-white-primary);
}
