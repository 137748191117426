.title {
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-weight: 500;
}

.description {
  margin-top: 20px;
  font-size: 0.875rem;
  color: var(--grey-1);
}

.description div + div {
  margin-top: 12px;
}

.buttons {
  margin-top: 32px;
}

.image {
  max-width: 100%;
}

.code {
  padding: 10px;
  border-radius: var(--border-radius-m);
  border: 1px solid var(--grey-3);
  background: var(--grey-6);
  white-space: normal;
}
