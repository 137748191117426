.table {
  color: var(--grey-0);
}

.cellIcon {
  width: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  line-height: 0;
}

.noItems {
  text-align: center;
}

.buttons {
  align-items: center;
  margin-top: auto;
}

.notice {
  margin-left: auto;
  text-align: right;
}

.noticeLink {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 1;
  text-decoration: underline;
}

.noticeText {
  font-size: 10px;
  line-height: 1.2;
}

.cancellation {
  margin: auto;
  max-width: 430px;
  text-align: center;
}

@media (min-width: 576px) {
  .buttonBack {
    min-width: 160px;
  }

  .buttonChange {
    min-width: 160px;
  }

  .noticeText {
    max-width: 220px;
  }
}

@media (min-width: 801px) {
  .buttonBack {
    min-width: 240px;
  }

  .buttonChange {
    min-width: 226px;
  }
}

@media (max-width: 575px) {
  .buttonBack {
    order: 1;
    width: 100%;
  }

  .notice {
    text-align: center;
  }
}