.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.item {
  margin-bottom: 16px;
}

.itemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 8px;
}

.itemTitle {
  font-size: 14px;
  line-height: 1.25;
}

.itemValue {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.25;
}

.progress {
  border-radius: var(--border-radius-s);
  height: 5px;
  background: var(--color-green-4);
}

.bar {
  height: inherit;
  border-radius: inherit;
  background: var(--color-green-primary);
}

.progress.error {
  background: var(--color-red-bg);
}

.progress.error .bar {
  background: #F36578;
}

.buttons {
  margin-top: auto;
}

.button {
  min-height: 40px;
  padding: 7px 16px;
  font-size: 0.9375rem;
  font-weight: 500;
  border: 1px solid var(--grey-5);
}
