.owners {
  max-height: 328px;
  padding-right: 20px;
  margin-right: -20px;
  overflow-y: auto;
}

.ownersButtons {
  margin-top: 15px;
}

.owner {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 11px 20px;
  border: 1px dashed var(--grey-3);
  border-radius: var(--border-radius-l);
  cursor: pointer;
  gap: 15px;
}

.owner.active {
  background: var(--color-green-3);
  border-style: solid;
  border-color: var(--color-green-3);
}

.ownerCheckbox {
  color: var(--grey-3);
  line-height: 0;
}

.ownerName {
  flex-grow: 1;
}

.ownerName span {
  font-weight: 600;
}

.ownerButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.owner.active .ownerCheckbox {
  color: var(--color-green-primary);
}

.remove:hover {
  color: var(--error-color);
}

@media (max-width: 575px) {
  .buttonAdd {
    width: 100%;
  }

  .owner {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .ownerCheckbox {
    width: 14px;
    margin: 8px 0;
  }

  .ownerName {
    flex-grow: 0;
    width: calc(100% - 30px);
  }

  .ownerButtons {
    margin-left: 30px;
  }
}
