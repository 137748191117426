.agreement,
.checkCodeText {
  margin-top: 10px;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--grey-2);
}

.agreement a {
  color: var(--primary-color);
  text-decoration: underline;
}

.checkCodeText {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.backButton {
  font-family: var(--main-font);
  color: var(--color-black-primary);
  gap: 0;
}

.inputs {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 30px;
}

.timer {
  margin-top: 50px;
  margin-bottom: 50px;
}

.anotherAction {
  margin-top: 20px;
  font-weight: 500;
  color: var(--grey-2);
}

.anotherAction a {
  color: var(--primary-color);
  text-decoration: underline;
}

.buttonsContainer {
  justify-content: center;
}

.resendText {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--grey-2);
}

.resendButton {
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: underline;
}

.mailButton {
  gap: 7px;
}

.mailButtonIcon {
  height: 24px;
  width: auto;
}

.notice {
  margin-top: 10px;
  padding: 15px 10px;
  background: var(--color-white-primary);
  color: #FFA88C;
  font-size: 0.875rem;
  border-radius: var(--border-radius-m);
}

@media (max-width: 575px) {
  .inputs {
    gap: 25px;
  }

  .timer {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
