.remove {
  color: var(--grey-3);
}

.remove:hover {
  color: var(--error-color);
}

.name span {
  display: inline-block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 1.8rem;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.tooltip:global(-content) {
  width: 250px;
  padding: 8px 10px;
}

@media (min-width: 576px) {
  .cell {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cell > a {
    display: block;
    max-width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (max-width: 575px) {
  .name {
    min-height: 0;
  }
}
