.root {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin-bottom: 38px;
  gap: 12px;
}

.search {
  flex-grow: 1;
}

.button {
  width: 100%;
  max-width: 210px;
  padding: 16px 10px;
}

.select :global(.rc-select-selector) {
  font-weight: 500;
}

.select :global(.rc-select-selection-placeholder) {
  color: var(--color-black-primary);
}

.selectCategories {
  width: 100%;
  max-width: 210px;
}

.addCategoryButton {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 52px;
  margin: 12px 10px 10px;
  border-radius: var(--border-radius-s);
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  padding: 4px 12px 4px 16px;
  color: var(--color-green-primary);
  gap: 8px;
  cursor: default;
}

.addCategoryButton:hover {
  background: var(--color-green-3);
}

.addCategoryButton::before {
  content: "";
  position: absolute;
  top: -7px;
  left: 5px;
  width: 95%;
  height: 1px;
  background-color: var(--grey-6);
}

.addCategoryButton span {
  line-height: 0;
}

@media (max-width: 1024px) {
  .root {
    flex-wrap: wrap;
  }

  .search {
    width: 100%;
  }

  .button,
  .select {
    flex: 1;
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .root button,
  .root a {
    width: 100%;
  }

  .select {
    margin-left: 0;
    max-width: 100%;
  }
}
