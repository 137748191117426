.root {
  position: relative;
  display: flex;
  align-items: center;
  height: 72px;
  padding: 18px 20px;
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-l);
  overflow: hidden;
  gap: 10px;
}

.root:not(:last-child) {
  margin-bottom: 10px;
}

.icon {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
}

.name {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  margin-left: auto;
  flex-shrink: 0;
  padding: 3px 10px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  background: var(--color-green-4);
  color: var(--color-green-primary);
  border-radius: var(--border-radius-xs);
}

.buttons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  flex-shrink: 0;
  display: flex;
  padding: 20px;
  background: linear-gradient(90deg, #F3F5F6 0%, #F9FAFA 33.42%, #FCFCFC 56.01%, #FFFFFF 100%);
  transform: translateX(110%);
  transition: transform var(--transition);
  gap: 8px;
}

.root.hover .buttons {
  transform: translateX(0);
}

.button {
  flex-shrink: 0;
  min-height: 36px;
  font-size: 0.875rem;
  line-height: 1.42;
}

.addButton {
  color: var(--grey-2);
}

.addButton:hover,
.addButton:focus-visible {
  box-shadow: var(--box-shadow-a);
  color: var(--grey-2);
}

.modalTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  padding: 0 15px 0 0;
  font-size: 1.125rem;
  font-weight: 500;
  gap: 20px;
}

.modalImage {
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-m);
  overflow: hidden;
}

.modalImage:not(:last-child) {
  margin-bottom: 20px;
}
