.question {
  margin-bottom: 10px;
}

.input {
  border-color: var(--grey-3);
}

.input:hover {
  border-color: var(--grey-2);
}

.input:focus {
  border-color: var(--primary-color);
}

.subtitle {
  margin-bottom: 15px;
  text-align: center;
}

.reversed > label {
  display: inline-flex;
  flex-direction: row-reverse;
}

.buttons {
  margin-top: 15px;
  justify-content: center;
}

.buttonAdd {
  min-height: 50px;
  min-width: 50px;
  padding: 8px;
}

.buttonAddIcon {
  width: 28px;
  height: 28px;
}

.answer + .answer {
  margin-top: 15px;
}

.answerContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.answerNumber {
  width: 20px;
  text-align: center;
}

.answerButtons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.answerDescription {
  margin: 10px 34px 0 30px;
}

.switcher {
  flex-grow: 0;
  white-space: nowrap;
}

.switcher > label {
  padding: 18px 12px;
  background: var(--grey-7);
  border-radius: var(--border-radius-l);
  font-size: 14px;
}

.buttonAddDescription {
  font-weight: 400;
  font-size: 16px;
}

.remove:hover,
.remove:focus-visible {
  color: var(--error-color);
}
