.save {
  max-width: 250px;
}

.description {
  text-align: center;
}

.content {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  gap: 20px;
}

.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dropzone {
  width: 377px;
  height: 285px;
}

.dropzone :global(.forma-ui__file-dropzone__placeholder) {
  max-width: 288px;
  line-height: 1.25;
}

@media (max-width: 575px) {
  .content {
    flex-direction: column;
  }

  .form {
    width: 100%;
  }

  .dropzone {
    width: 100%;
    height: auto;
  }

  .save {
    max-width: 100%;
  }
}
