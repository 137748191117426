.resultSubtitle {
  text-align: center;
  max-width: 500px;
  margin: 0 auto 26px;
  font-weight: 500;
}

.modal {
  display: flex;
  flex-direction: column;
  min-height: 439px;
}

.loader {
  margin-top: auto;
  margin-bottom: auto;
}

.modalTitle {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.modalDescr {
  margin: 24px auto 0;
  max-width: 530px;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.1;
}

.modalContent {
  display: flex;
  justify-content: center;
  gap: 3%;
}

.modalButtons {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.timeHead {
  margin-bottom: 4px;
  font-family: var(--headings-font);
  font-size: 14px;
}

.timeItems {
  margin-top: 20px;
  width: 304px;

  /* min-height: 258px; */
}

@media (min-width: 576px) {
  .modalButton {
    width: 100%;
    max-width: 276px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .timeItems {
    width: 230px;
  }
}

@media (max-width: 640px) {
  .modalContent {
    gap: 12px
  }
}

@media (max-width: 575px) {
  .modalContent {
    justify-content: center;
  }

  .timeItems {
    width: 100%;
  }
}