.root {
  display: flex;
  align-items: center;
  padding: 9px 20px;
  padding-left: 114px;
  gap: 15px;
}

.root:global(.success) {
  background: var(--color-green-4);
  color: var(--color-green-primary);
}

.root:global(.attention) {
  background: #FEF8DD;
  color: #867E5A;
}

.root:global(.error) {
  background: #FFC8C8;
  color: #AC2828;
}

.text {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  gap: 5px;
}

.link {
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: underline;
}

.link:hover,
.link:focus-visible {
  text-decoration: underline;
}

.icon {
  line-height: 0;
  width: 16px;
  height: 16px;
}

.icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 575px) {
  .root {
    padding: 10px var(--container-padding);;
  }
}
