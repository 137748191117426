.subtitle {
  text-align: center;
}

.bannerWrapper {
  display: flex;
  justify-content: center;
}

.banner {
  position: relative;
  margin: 70px auto;
  padding: 20px 36px;
}

.banner::before {
  content: '';
  display: block;
  position: absolute;
  inset: 0;
  background: var(--grey-6);
  filter: blur(15px);
  border-radius: 60px;
  z-index: -1;
}

.bannerContent {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.bannerText {
  max-width: 250px;
  margin-top: 8px;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--grey-2);
}

.bannerButtons {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.modalButtons button {
  flex: 0 0 250px;
}