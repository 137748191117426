.inputs {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-3);
  gap: 20px;
}

.dates {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.datesDivider {
  display: flex;
  align-items: center;
  height: 56px;
}

.headButtons {
  margin-top: 0;
}

.headButtons + div {
  margin-top: 20px;
}

.button {
  min-height: 36px;
  padding: 6px 16px;
  font-size: 14px;
}

.category {
  display: flex;
  align-items: center;
  gap: 5px;
}

.categoryRemove {
  line-height: 0;
  color: var(--color-black-primary);
}

.category svg {
  width: 11px;
  height: 11px;
}
