.modalContent {
  margin: 0 0 30px;
  width: 500px;
}

.modalContent input {
  width: 100%;
  border-color: var(--grey-4);
}

@media (max-width: 800px) {
  .modalContent {
    width: 100%;
  }
}
