.description {
  text-align: center;
}

.imageWrap {
  margin-top: 74px;
  text-align: center;
}

@media (min-width: 576px) {
  .modalButton {
    flex-basis: 240px;
    width: 240px;
  }

  .imageWrap {
    margin-top: 95px;
    margin-bottom: 70px;
  }
}
