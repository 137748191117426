.form :global(.forma-ui__fill-group__root) {
  margin-bottom: 0;
}

.modalField {
  max-width: 866px;
  margin: 0 auto;
  padding: 20px 5px 10px;
}

.save {
  flex-basis: auto;
  width: auto;
}

.subtitle {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 500;
}
