.container {
  padding: 30px;
  border: 1px solid var(--color-green-primary);
  border-radius: var(--border-radius-l);
  background: var(--body-bg);
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 22px;
}

.buttons {
  justify-content: flex-end;
}

.dropdownSwitches {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 12px;
  margin-bottom: 15px;
}

.checkedIcon {
  line-height: 20px;
  color:var(--color-green-primary);
}

@media (max-width: 800px) {
  .items {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .dropdownSwitches {
    grid-template-columns: 1fr;
  }
}
