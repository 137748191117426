.request-modal .react-datepicker {
  background: none;
  border: none;
  box-shadow: none;
}

.request-modal .react-datepicker__header {
  background: none;
  border: none;
  padding: 7px 0 0;
}

.request-modal .react-datepicker__current-month {
  display: block;
}

.request-modal .react-datepicker__header__dropdown {
  margin: 0;
}

.request-modal .react-datepicker__navigation {
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background: var(--grey-5);
  text-indent: 0;
}

.request-modal .react-datepicker__navigation:hover,
.request-modal .react-datepicker__navigation:focus {
  background: var(--grey-4);
}

.request-modal .react-datepicker__navigation--previous {
  left: auto;
  right: 38px;
}

.request-modal .react-datepicker__navigation-icon {
  position: static;
  width: auto;
  line-height: 0;
}

.request-modal .react-datepicker__navigation-icon::before {
  display: none;
}

.request-modal .react-datepicker__year-read-view--down-arrow,
.request-modal .react-datepicker__month-read-view--down-arrow,
.request-modal .react-datepicker__month-year-read-view--down-arrow {
  border-color: var(--color-black-primary);
}

.request-modal .react-datepicker__current-month {
  text-transform: capitalize;
}

.request-modal .react-datepicker__current-month,
.request-modal .react-datepicker-time__header,
.request-modal .react-datepicker-year-header {
  text-align: left;
  font-family: var(--headings-font);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.request-modal .react-datepicker__day-names {
  margin-top: 14px;
}

.request-modal .react-datepicker__day-name {
  font-weight: 600;
}

.request-modal .react-datepicker__day-name,
.request-modal .react-datepicker__day,
.request-modal .react-datepicker__time-name {
  margin: 0;
  width: 38px;
  line-height: 38px;
  font-size: 14px;
  user-select: none;
  transition: var(--transition);
}

.request-modal .react-datepicker__day {
  border-radius: 10px;
  border: 1px solid var(--grey-4);
}

.request-modal .react-datepicker__day--outside-month {
  color: var(--grey-4)
}

.request-modal .react-datepicker__day--outside-month:hover {
  background: none;
}

.request-modal .react-datepicker__day:hover,
.request-modal .react-datepicker__month-text:hover,
.request-modal .react-datepicker__quarter-text:hover,
.request-modal .react-datepicker__year-text:hover {
  border-radius: 10px;
  background: var(--color-green-4);
}


.request-modal .react-datepicker__day--keyboard-selected,
.request-modal .react-datepicker__month-text--keyboard-selected,
.request-modal .react-datepicker__quarter-text--keyboard-selected,
.request-modal .react-datepicker__year-text--keyboard-selected {
  background: none;
  color: var(--color-black-primary);
}

.request-modal .react-datepicker__day--selected,
.request-modal .react-datepicker__day--in-selecting-range,
.request-modal .react-datepicker__day--in-range,
.request-modal .react-datepicker__month-text--selected,
.request-modal .react-datepicker__month-text--in-selecting-range,
.request-modal .react-datepicker__month-text--in-range,
.request-modal .react-datepicker__quarter-text--selected,
.request-modal .react-datepicker__quarter-text--in-selecting-range,
.request-modal .react-datepicker__quarter-text--in-range,
.request-modal .react-datepicker__year-text--selected,
.request-modal .react-datepicker__year-text--in-selecting-range,
.request-modal .react-datepicker__year-text--in-range {
  background: var(--color-green-primary);
}


.request-modal .react-datepicker__day--selected:hover,
.request-modal .react-datepicker__day--in-selecting-range:hover,
.request-modal .react-datepicker__day--in-range:hover,
.request-modal .react-datepicker__month-text--selected:hover,
.request-modal .react-datepicker__month-text--in-selecting-range:hover,
.request-modal .react-datepicker__month-text--in-range:hover,
.request-modal .react-datepicker__quarter-text--selected:hover,
.request-modal .react-datepicker__quarter-text--in-selecting-range:hover,
.request-modal .react-datepicker__quarter-text--in-range:hover,
.request-modal .react-datepicker__year-text--selected:hover,
.request-modal .react-datepicker__year-text--in-selecting-range:hover,
.request-modal .react-datepicker__year-text--in-range:hover {
  background: var(--color-green-primary);
}

.request-modal .react-datepicker__day--disabled {
  pointer-events: none;
}

.request-modal .modal__buttons {
  margin-top: 30px;
  flex-direction: column;
}

.request-modal .react-datepicker__day--today:not(.react-datepicker__day--selected) {
  color: var(--color-green-primary);
}
