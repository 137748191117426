.header {
  margin-bottom: 30px;
  text-align: center;
}

.wrapperAddButton {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 58px;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
