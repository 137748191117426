.root {
  padding: 48px 30px;
  background: var(--grey-6);
  border-radius: var(--border-radius-l);
}

.buttons {
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 50px;
}

.item {
  height: 165px;
}

.noItems {
  width: 100%;
  margin: 70px 0;
  font-weight: 600;
  color: var(--grey-2);
  text-align: center;
  grid-column: span 5;
}

@media (max-width: 1024px) {
  .root {
    padding: 28px 16px;
  }
}

@media (max-width: 575px) {
  .root {
    padding: 24px 12px;
  }
}

@media (max-width: 1024px) {
  .noItems,
  .group {
    grid-column: span 4;
  }
}

@media (max-width: 800px) {
  .noItems,
  .group {
    grid-column: span 3;
  }
}

@media (max-width: 575px) {
  .noItems,
  .group {
    grid-column: span 2;
  }
}
