.modalSelect {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 15px;
}

.groups {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.dropdownSwitches {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 12px;
}

.modalButtons {
  margin-top: 17px;
}

.searchResults {
  margin: 0 -15px;
  padding: 0 15px;
  height: 241px;
  overflow-y: auto;
}

.searchResults > .dropdownSwitch {
  margin-bottom: 10px;
}

.searchItemLabel {
  display: flex;
  justify-content: space-between;
}

.searchItemGroup {
  margin-left: auto;
  color: var(--grey-2);
}

.topButtons {
  text-align: center;
}

.howToButton {
  text-decoration: underline;
  font-weight: 500;
}

@media (max-width: 800px) {
  .modalSelect {
    width: 100%;
  }

  .searchResults {
    height: 227.5px;
  }
}

@media (max-width: 575px) {
  .dropdownSwitches {
    grid-template-columns: 1fr;
  }
}
