.root {
  max-width: 720px;
  margin: 35px auto;
}

.head {
  margin-top: 0;
  margin-bottom: 18px;
  gap: 13px;
}

.head button {
  flex: 1;
}

.container {
  position: relative;
}

.container.active::after {
  content: '';
  position: absolute;
  inset: -6px -6px -6px -10px;
  border-radius: var(--border-radius-l);
  background: var(--color-green-3);
  z-index: -1;
}

.container.active .searchInput {
  background: var(--color-white-primary);
}

.container.active .item {
  color: var(--color-black-primary);
}

.items {
  position: relative;
  min-height: 40px;
  margin-top: 18px;
}

.item td {
  cursor: pointer;
}

.table table {
  min-width: 0;
}

.noItems {
  padding: 15px 20px 25px;
  text-align: center;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.subtitle {
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
}