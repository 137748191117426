.message {
  margin: 25px 0;
  line-height: 1.2;
  text-align: center;
}

.tags {
  justify-content: center;
}

.button {
  min-width: 240px;
  margin: 0 auto;
}
