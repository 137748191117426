.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.cardUser {
  width: calc(100% - 330px - 16px);
}

.cardLimits {
  width: 330px;
}

.cardGroups,
.cardLang {
  width: calc(50% - 8px);
}

.cardEntities {
  width: 100%;
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
}

.form > div:first-child {
  flex: 1 1 100%;
}

.form :global(.forma-ui__field-container__label) {
  font-size: 14px;
  font-weight: 400;
}

.form {
  --input-height: 40px;
  --input-font-size: 14px;
  --input-font-weight: 400;
  --input-radius: var(--border-radius-m);
  --input-padding-y: 7px;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin-top: 0;
  gap: 8px;
}

.button {
  /* flex: 1; */
  min-height: 40px;
  padding: 7px 16px;
  border: 1px solid var(--grey-5);
}

.button:hover,
.button:focus-visible {
  border: 1px solid var(--grey-5);
}

.groupsHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.groupsButton {
  padding: 8px;
}

.groupsButtonIcon {
  width: 18px;
  height: 18px;
}

.organizations {
  margin-top: 16px;
}

.description {
  line-height: 1.25;
}

.link {
  color: var(--primary-color);
  text-decoration: underline;
}

.passwordForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 1024px) {
  .cardUser,
  .cardLimits {
    width: 100%;
  }

  .cardGroups {
    width: calc(100% - 266px);
  }

  .cardLang {
    width: 250px;
  }
}

@media (max-width: 800px) {
  .cardGroups {
    width: calc(100% - 181px);
  }

  .cardLang {
    width: 165px;
  }

  .buttons {
    grid-template-columns: repeat(2, 1fr);
  }

  .buttonPlaceholder {
    display: none;
  }
}

@media (max-width: 575px) {
  .form {
    flex-direction: column;
  }

  .form > div:first-child {
    flex: 1;
  }

  .cardGroups,
  .cardLang {
    width: 100%;
  }

  .buttons {
    grid-template-columns: 1fr;
  }
}
