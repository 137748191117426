.button {
  display: inline-flex;
  justify-content: space-between;
  padding: 0;
  line-height: 0;
  color: var(--grey-7);
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: all var(--transition);
}

.button:hover {
  color: var(--grey-6);
}

.button.active {
  transform: rotate(90deg);
}

.renameModal {
  margin: 0 0 30px;
}

.renameModal input {
  width: 100%;
  border-color: var(--grey-4);
}

.iconChecked {
  line-height: 0;
  color: var(--primary-color);
  margin-left: auto;
}

/* :global(.onboarding-show) .button {
  display: none;
} */
