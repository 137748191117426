.cellIcon {
  width: 52px;
  line-height: 0;
  cursor: pointer;
}

.docIcon {
  cursor: pointer;
}

.cellButtons {
  width: 52px;
  line-height: 0;
  cursor: pointer;
}

.cellType {
  width: 18%;
  cursor: pointer;
}

.cellName {
  width: 31%;
  cursor: pointer;
}

.cellStatus {
  width: 20%;
  cursor: pointer;
}

.cellDate {
  width: 22%;
  cursor: pointer;
}

.download:hover,
.download:focus-visible {
  color: var(--color-blackk-primary);
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 180px;
}

.noItems {
  padding: 11px 0;
  text-align: center;
}
