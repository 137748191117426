.emptyDescription {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
}

.copyDescription {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
}

.copyButtons {
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.fillButton {
  max-width: 324px;
}

.editButton {
  text-decoration: underline;
}