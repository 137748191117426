.root {
  display: flex;
  align-items: center;
  max-width: 355px;
  padding: 16px;
  border-radius: var(--border-radius-l);
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-green);
  cursor: pointer;
  gap: 16px;
}

.timer {
  font-size: 12px;
  color: var(--grey-2);
}

.title {
  margin-bottom: 4px;
  line-height: 1.25;
}

@media (max-width: 800px) {
  .root {
    flex-direction: column;
    text-align: center;
  }
}
