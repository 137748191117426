.items {
  padding-top: 6px;
  padding-bottom: 6px;
}

.item {
  position: relative;
  margin-bottom: 10px;
}

.button {
  position: relative;
  padding-right: 26px;
  box-shadow: var(--box-shadow-a);
}

.handler {
  position: absolute;
  top: 0;
  right: 8px;
  height: 100%;
  width: 16px;
  padding: 11px 4px;
  text-align: center;
  color: var(--grey-3);
  background: inherit;
  transition: inherit;
}
