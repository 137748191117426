.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow-a);
  background: var(--color-white-primary);
  padding: 5px 12px;
}

.text {
  flex-grow: 1;
  padding-right: 12px;
  font-size: 0.812rem;
  cursor: pointer;
}

.input {
  height: 20px;
  padding: 0 12px 0 0;
  border: none;
  font-size: 0.812rem;
}

.delete {
  margin-left: auto;
}

.delete:hover,
.delete:focus-visible {
  color: var(--error-color);
}

.deleteIcon {
  width: 20px;
  height: 20px;
}