.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 890px;
  margin: 0 auto;
  gap: 20px;
}

.column {
  min-height: 420px;
  padding: 20px 25px;
  background: var(--grey-6);
  border-radius: var(--border-radius-l);
}

@media (max-width: 575px) {
  .root {
    grid-template-columns: 1fr;
  }
}
