.root {
  display: flex;
  gap: 16px;
}

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px 32px 32px;
  background-color: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  border: 1px solid transparent;
  gap: 64px;
}

.item.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.item.promo {
  background-image: url('/public/images/promo-bg.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 120%;
}

.item.selected {
  border-color: var(--primary-color);
}

.name {
  margin-bottom: 32px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
}

.price {
  margin-bottom: 6px;
  line-height: 1.3;
}

.priceCurrent {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.2;
}

.priceOld {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: var(--grey-2);
  text-decoration: line-through;
}

.priceDuration {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: var(--grey-2);
}

.priceBase {
  color: var(--grey-2);
}

.button {
  margin-top: 16px;
}

.buttonNotice {
  margin-top: 16px;
  font-size: 12px;
  line-height: 1.16;
  color: var(--grey-2);
  text-align: center
}

.feature {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.142;
  color: var(--grey-0);
  gap: 8px;
}

.feature:not(:last-child) {
  margin-bottom: 16px;
}

.featureIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
}

.featureIcon::before {
  content: '';
  display: block;
  width: 12px;
  height: 6px;
  background: var(--primary-color);
  border-radius: var(--border-radius-s);
}

.label {
  height: 19px;
  margin-bottom: 8px;
  color: var(--primary-color);
  font-size: 0.9375rem;
  line-height: 1.25;
}

.item :global(.skeleton-loader) {
  border-radius: var(--border-radius-s);
}

.name:global(.skeleton-loader) {
  height: 1.7rem;
  width: 142px;
}

.oldPrice:global(.skeleton-loader) {
  height: 1rem;
  width: 99px;
}

.price:global(.skeleton-loader) {
  height: 1.4rem;
  margin-bottom: 10px;
  width: 150px;
}

.featureIcon:global(.skeleton-loader) {
  background: none;
  color: var(--grey-4);
}

.featureTextRow:global(.skeleton-loader) {
  display: block;
  margin-bottom: 5.5px;
  height: 0.875rem;
  width: 100%;
}

.additionalCount {
  margin-top: 6px;
  font-size: 12px;
  line-height: 1.16;
  color: var(--grey-2);
}

.additionalDescr {
  margin-bottom: 6px;
}

.additionalCount :global(.forma-ui__quantity__root) {
  padding: 0;
}

.additionalCount :global(.forma-ui__quantity__input) {
  height: 34px;
  width: 41px;
  font-size: 15px;
  font-weight: 500;
}

.additionalCount :global(.forma-ui__quantity__button) {
  height: 34px;
  width: 34px;
  background: var(--color-white-primary);
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-s);
  color: var(--grey-0);
}

@media (max-width: 1200px) {
  .item.promo {
    background-size: auto 100%;
  }
}

@media (max-width: 1024px) {
  .root {
    flex-direction: column;
  }

  .label {
    height: auto;
  }

  .label:empty {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) and (min-width: 576px) {
  .item {
    flex-direction: row;
    gap: 24px;
  }
}

@media (max-width: 800px) {
  .item {
    padding: 16px 24px 24px;
  }
}

@media (max-width: 575px) {
  .item {
    padding: 15px 15px 20px;
    gap: 32px;
  }
}
