.root {
  background: var(--grey-7);
  box-shadow: var(--box-shadow-a);
  border-radius: var(--border-radius-l);
}

.header {
  display: flex;
  align-items: flex-start;
  padding: 12px 15px;
  cursor: pointer;
  gap: 16px;
}

.title {
  flex-grow: 1;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
}

.arrow {
  flex-shrink: 0;
  width: 24px;
  line-height: 0;
  transition: transform var(--transition);
}

.arrow.open {
  transform: scaleY(-1);
}

.content {
  padding: 5px 13px 17px;
}

.root.white {
  background: var(--color-white-primary);
}

.root.empty .title {
  color: var(--grey-1);
}

.root.medium .header {
  padding-top: 8px;
  padding-bottom: 8px;
}

.root.medium .title {
  padding-left: 0;
  text-align: left;
}

@media (max-width: 800px) {
  .header {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .title {
    line-height: 1.5;
  }
}
