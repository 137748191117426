.title {
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.add {
  display: flex;
  margin-bottom: 45px;
  gap: 12px;
}

.input {
  background: none;
  border-color: var(--grey-3);
}

.input:hover {
  border-color: var(--grey-2);
}

.input:focus {
  border-color: var(--primary-color);
}

.edit {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px 12px;
}

.editItem {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.editName {
  flex-grow: 1;
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-l);
  padding: 9px 72px 9px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteButton,
.editButton {
  position: absolute;
  top: 50%;
  right: 10px;
  padding: 0;
  transform: translateY(-50%);
}

.deleteButton:hover {
  color: var(--error-color);
}

.editButton {
  right: 42px;
}

.submitButton {
  padding: 9px;
  min-height: 0;
}

.submitButtonIcon {
  height: 16px;
  width: 16px;
  border-radius: var(--border-radius-l);
}

.editInput {
  height: 44px;
  padding: 9px 16px;
  background: none;
  border-color: var(--grey-3);
  border-radius: var(--border-radius-l);
}

.editInput:hover {
  border-color: var(--grey-2);
}

.editInput:focus {
  border-color: var(--primary-color);
}

@media (max-width: 800px) {
  .edit {
    grid-template-columns: 1fr;
  }

  .add {
    flex-direction: column;
  }

  .editButton {
    right: 48px;
  }
}
