.headButtons {
  margin-bottom: 20px;
}

.headButtons button {
  min-width: 210px;
}

.table {
  padding: 20px 34px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
}

.rowLoading {
  height: 42px;
}

.row {
  display: flex;
  align-items: center;
  min-height: 40px;
  gap: 16px;
}

.row:not(:last-child) {
  margin-bottom: 30px;
}

.rowNameLoader {
  display: inline-block;
  width: 100%;
  max-width: 200px;
  height: 24px;
  vertical-align: top;
  border-radius: var(--border-radius-xs);
}

.column {
  width: 15%;
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--grey-1);
}

.column:not(:first-child) {
  text-align: center;
}

.columnName {
  flex-shrink: 0;
  width: 160px;
}

.header {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--grey-7);
}

.header .column {
  font-weight: 600;
  color: var(--grey-2);
}

.header .column:not(:first-child):hover {
  color: var(--primary-color);
  cursor: pointer;
}

.checkbox {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0;
  background: var(--grey-7);
  border-radius: var(--border-radius-xs);
  line-height: 0;
  cursor: pointer;
  outline: none;
}

.checkbox svg {
  height: 16px;
  width: 16px;
}

.checkbox .checkboxInput {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

:global([role="tooltip"].groups-tooltip-content) {
  padding: 20px;
  max-width: 410px;
  font-size: 0.875rem;
}

.modalContent input {
  width: 100%;
  border-color: var(--grey-4);
}

@media (max-width: 1024px) {
  .headButtons button {
    width: 100%;
  }

  .row {
    flex-direction: column;
  }

  .row .column {
    text-align: left;
  }

  .column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
  }

  .columnName {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 1rem;
  }

  .columnDescr {
    margin-bottom: 6px;
  }

  .checkbox {
    width: 32px;
    height: 32px;
  }
}