.root {
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: 1.25rem;
}

.subtitle {
  margin-top: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--grey-2);
}

.buttons {
  justify-content: center;
}
