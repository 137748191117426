.save {
  max-width: 240px;
}

.checkbox {
  margin-top: 20px;
}

.checkbox > label {
  justify-content: center;
}

.checkbox a {
  color: var(--primary-color);
}

@media (max-width: 575px) {
  .save {
    max-width: 100%;
  }
}