.root {
  min-height: 306px;
  padding: 24px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
}

.title {
  margin-bottom: 16px;
}

.description {
  display: flex;
  gap: 40px;
}

.image {
  width: 40%;
  margin-top: auto;
  margin-bottom: -15px;
}

.status {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  line-height: 1;
  gap: 16px;
}

.progress {
  flex-grow: 1;
  height: 4px;
  border-radius: var(--border-radius-l);
  background: var(--color-green-4);
  overflow: hidden;
}

.bar {
  height: 100%;
  width: 0;
  border-radius: var(--border-radius-l);
  background: var(--primary-color);
}

.time {
  width: 62px;
  font-size: 14px;
  color: var(--grey-0);
}

.steps {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.step {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 4px;
  font-size: 14px;
  line-height: 1.44;
  color: var(--grey-2);
  gap: 6px;
}

.step.active {
  color: var(--color-black-primary);
  background: var(--color-green-4);
  border-radius: var(--border-radius-s);
}

.step.active .stepIcon {
  color: var(--primary-color);
}

.stepIcon {
  line-height: 0;
}

@media (max-width: 1280px) {
  .description {
    gap: 20px;
  }
}

@media (max-width: 1024px) {
  .image {
    display: none;
  }
}
