.draggable {
  cursor: grabbing;
}

.group {
  padding-left: 4%;
}

.groupItems {
  justify-content: center;
}

.dropContainer {
  height: 100%;
  flex-grow: 1;
}

.groupLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 53px 0;
  color: var(--color-white-primary);
}

.modalMove {
  width: 500px;
}

.loader {
  padding: 16px;
  color: var(--color-white-primary);
}

.noItems {
  position: relative;
  margin: 0 -28px;
  padding: 14px 28px;
  background: var(--color-green-3);
  border-radius: var(--border-radius-l);
  grid-column: span 5;
}

@media (max-width: 1366px) {
  .noItems {
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 1024px) {
  .noItems,
  .group {
    grid-column: span 4;
  }
}

@media (max-width: 800px) {
  .noItems,
  .group {
    grid-column: span 3;
  }
}

@media (max-width: 575px) {
  .noItems,
  .group {
    grid-column: span 2;
  }
}
