.emptyDescription {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
}

.downloadDescription {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 575px) {
  .downloadDescription {
    width: 100%;
  }
}
