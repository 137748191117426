.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 438px;
}

.description {
  max-width: 750px;
  margin: 8px auto 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}