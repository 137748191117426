.root {
  display: flex;
  gap: 20px;
}

.right {
  width: 51%;
  flex-shrink: 0;
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: var(--main-font);
  font-size: 2rem;
  line-height: 1;
}

.description {
  font-weight: 600;
  color: var(--grey-1);
}

.descriptionIcon {
  margin-left: 4px;
  vertical-align: bottom;
}

.list {
  margin: 32px 0;
  padding: 0;
  list-style-type: none;
}

.listItem {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: var(--grey-1);
  line-height: 1.25;
  gap: 6px;
}

.listItem::before {
  flex-shrink: 0;
  content: '';
  width: 8px;
  height: 8px;
  background: var(--color-green-primary);
  border: 3px solid var(--color-green-3);
  border-radius: var(--border-radius-xl);
}

.listItem:not(:last-child) {
  margin-bottom: 8px;
}

.buttonsBlock {
  margin-top: 16px;
  justify-content: flex-end;
  gap: 24px;
}

.buttonsBlock > a,
.buttonsBlock > button {
  font-size: 15px;
}

.skipModal {
  text-align: center;
  font-size: 14px;
}

button.skipModalButton {
  padding-left: 10px;
  padding-right: 10px;
}

.dropzone {
  height: 306px;
}

.dropzoneContent {
  max-width: 440px;
  margin: 0 auto;
}

.dropzoneSkelet {
  height: 306px;
  border-radius: var(--border-radius-l);
}

.dropzoneIcon {
  vertical-align: bottom;
}

@media (max-width: 800px) {
  .root {
    flex-direction: column;
  }

  .right {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .title {
    font-size: 32px;
  }

  .buttonsBlock {
    justify-content: stretch;
  }

  .dropzone {
    height: auto;
  }

  .dropzoneContent {
    max-width: 575px;
    margin: 0;
  }
}
