.root {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  width: 70px;
  padding: 4.5px;
  background: var(--grey-5);
  border-radius: var(--border-radius-l);
  font-size: 14px;
  text-align: center;
  transition: var(--transition);
  outline: none;
  cursor: pointer;
}

.item:hover,
.item:focus-visible {
  background: var(--color-green-4);
  color: var(--color-green-primary);
}

.item.selected {
  background: var(--color-green-primary);
  color: var(--color-white-primary);
  font-weight: 600;
}

.item.disabled {
  background: var(--grey-6);
  color: var(--grey-2);
  pointer-events: none;
}

@media (max-width: 575px) {
  .root {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
  }
}