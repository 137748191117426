.root {
  position: relative;
  min-height: 48px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow-c);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 36px 15px 20px;
  cursor: pointer;
  gap: 10px;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.templateName {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--grey-2);
}

.deleted {
  color: var(--color-red-primary);
}

.dragging {
  cursor: grabbing;
}

.content {
  padding: 15px 36px 15px 20px;
}

.arrow {
  line-height: 0;
  transition: transform var(--transition);
}

.arrow.open {
  transform: scaleY(-1);
}

.handler {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 16px;
  padding: 13px 4px;
  text-align: center;
  background: var(--grey-5);
  color: var(--grey-3);
  border-radius: 0 var(--border-radius-m) var(--border-radius-m) 0;
  transition: background var(--transition), color var(--transition);
  cursor: pointer;
}

.folder + .folder {
  margin-top: 8px;
}

.folderName {
  font-size: 0.9375rem;
  font-weight: 500;
  color: var(--grey-2);
}

.folderTattrs {
  margin-top: 8px;
}

.tattrName {
  margin-top: 4px;
  font-size: 0.875rem;
  color: var(--grey-3);
}
