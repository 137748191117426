.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 50px;
  padding: 10px;
  border-radius: var(--border-radius-xxl);
  background: var(--color-white-primary);
  overflow: hidden;
  transition: max-width var(--transition), padding var(--transition);
  box-shadow: var(--box-shadow-a);
  cursor: pointer;
  gap: 5px;
}

.root.hover {
  max-width: 260px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: var(--box-shadow-blue);
}

.text {
  flex-shrink: 0;
  width: 180px;
  flex-grow: 1;
  font-size: 12px;
  line-height: 1.2;
}

.icon {
  flex-shrink: 0;
}

@media (max-width: 575px) {
  .root,
  .root.hover {
    padding: 10px;
  }
}