.circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  background: var(--grey-6);
  border-radius: 1000px;
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 600;
}