.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 32px;
  padding: 32px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  gap: 16px;
}

.title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
}

.description {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.28;
}

.image {
  width: 100%;
}

.row {
  display: flex;
  gap: 16px;
}

.qty {
  width: 187px;
  height: 108px;
  padding: 35px 0 13px;
  background-image: url('/public/images/signature-sheet-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
}

.qtyInfo {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.28;
}

.qty :global(.forma-ui__quantity__root) {
  display: inline-flex;
  padding: 0;
  background: none;
}

.qty :global(.forma-ui__quantity__button) {
  width: 34px;
  height: 34px;
  background: var(--color-white-primary);
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-s);
  color: var(--grey-0);
}

.qty :global(.forma-ui__quantity__input) {
  height: 34px;
  width: 88px;
  font-size: 15px;
  font-weight: 500;
  background: none;
}

.discountsItem {
  display: flex;
  font-size: 14px;
  line-height: 1.3;
  gap: 6px;
}

.discountsItemLabel {
  width: 60px;
}

.buttons {
  align-items: center;
  margin-top: 16px;
}

.button {
  min-width: 186px;
}

.price {
  line-height: 1.2;
}

.priceCurrent {
  font-size: 1.125rem;
  font-weight: 600;
}

.discount {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
}

.priceOld {
  margin-left: 8px;
  font-size: 14px;
  color: var(--grey-2);
  text-decoration: line-through;
}

@media (max-width: 1024px) {
  .root {
    grid-template-columns: 1fr;
  }

  .image {
    display: none;
  }
}

@media (max-width: 575px) {
  .button {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .row {
    flex-direction: column-reverse;
    align-items: center;
  }
}