.cellIcon {
  width: 60px;
}

.cellCreated,
.cellSigned {
  width: 125px;
}

.cellButtons {
  width: 60px;
}
