.root {
  max-width: 395px;
  margin: 85px auto;
}

.image {
  width: 100%;
  padding: 10px;
  pointer-events: none;
}

.description {
  margin-top: 120px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
  color: var(--grey-2);
}

.root :global(.slider__dot) {
  background: var(--color-white-primary);
}

.root :global(.slider__dot--active),
.root :global(.slider__dot:hover) {
  background: var(--primary-color);
}
