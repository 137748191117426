div.modal {
  min-height: 590px;
  padding: 0 0 40px;
  text-align: center;
}

.image {
  width: 100%;
  height: auto;
}

.title {
  margin-bottom: 10px;
  padding: 0 20px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
}

.description {
  padding: 0 20px;
}

.buttons {
  margin: 55px 20px 0;
  justify-content: center;
}

@media (min-width: 576px) {
  div.modal {
    min-height: 600px;
  }

  .button {
    min-width: 240px;
  }
}

@media (max-width: 1024px) {
  .modal > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .buttons {
    margin-top: auto;
  }

  .image {
    margin-left: -25%;
    margin-right: -25%;
    width: calc(150%);
  }
}