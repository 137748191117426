.headButtons {
  margin-top: 2px;
}

.activityButton {
  flex-direction: row-reverse;
  gap: 10px;
}

.activityButton:hover {
  color: var(--color-black-primary);
}
