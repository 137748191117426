.modal {
  display: flex;
  flex-direction: column;
  min-height: 480px;
}

.methods {
  margin-bottom: auto;
}

.buttons {
  align-items: center;
  gap: 10px 16px;
}

.notice {
  font-size: 12px;
  line-height: 1.17;
  color: var(--grey-2);
}

.total {
  display: flex;
  align-items: center;
  text-align: right;
  margin-left: auto;
  gap: 16px;
}

.totalNoticeLink {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  text-decoration: underline;
  cursor: pointer;
}

.totalNoticeLink:hover,
.totalNoticeLink:focus {
  text-decoration: underline;
}

.totalPrice {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1025px) {
  .paymentButton {
    min-width: 240px;
  }

  .notice {
    max-width: 360px;
  }
}

@media (max-width: 1024px) {
  .buttons {
    padding-bottom: 5px;
  }

  .total {
    margin-bottom: 0;
  }

  .paymentButton {
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .notice {
    text-align: center;
  }

  .total {
    margin: 0 auto;
    text-align: center;
  }
}
