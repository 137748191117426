.inviteSuccess {
  margin-top: 150px;
  text-align: center;
}

.inviteSuccessText {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 1.25rem;
  font-weight: 500;
}

.inviteSuccessButtons {
  justify-content: center;
}

.inviteSuccessButton {
  min-width: 330px;
}

@media (max-width: 800px) {
  .fields {
    grid-template-columns: repeat(2, 1fr);
  }

  .inviteSuccess {
    margin-top: 70px;
  }
}

@media (max-width: 575px) {
  .fields {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .inviteSuccess {
    margin-top: 0;
  }

  .inviteSuccessButton {
    min-width: 0;
  }
}
