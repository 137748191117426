.cell {
  cursor: pointer;
}

.icon {
  width: 32px;
  line-height: 0;
}

.icon > * {
  margin-bottom: -5px;
}

.icon svg {
  width: 100%;
  height: 100%;
}

.name {
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 500;
}

.nameOverflow {
  display: inline-block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: calc(0.875rem * 1.2 * 2);
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.date {
  font-size: 0.8125rem;
}

.optionsButton {
  display: inline-flex;
  justify-content: center;
  padding: 0;
  line-height: 0;
  color: var(--grey-5);
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: all var(--transition);
}

.optionsButton:hover {
  color: var(--grey-6);
}

.optionsButton.active {
  transform: rotate(90deg);
}

@media (max-width: 575px) {
  .item {
    display: flex;
    background: var(--color-white-primary);
    border-radius: var(--border-radius-m);
  }

  .item:not(:last-child) {
    margin-bottom: 15px;
  }

  .itemContent {
    flex-grow: 1;
  }

  .controls {
    flex-shrink: 0;
    padding: 10px 10px 10px 0;
  }

  .date {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-black-primary);
    padding: 0 10px 10px;
    gap: 0 10px;
  }

  .dateLabel {
    color: var(--grey-2);
    font-weight: 500;
  }

  .info {
    display: flex;
    flex-grow: 1;
  }

  .icon {
    flex-shrink: 0;
    padding: 10px 0 10px 10px;
    width: 48px;
  }

  .name {
    flex-grow: 1;
    padding: 10px;
  }
}
