.root {
  padding: 25px 18px;
  border-radius: var(--border-radius-l);
  background: var(--color-white-primary);
}

.title {
  font-weight: 700;
}

.title > label {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.buttons {
  justify-content: center;
  margin-top: 20px;
}

.item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.number {
  width: 28px;
  text-align: center;
}

.input {
  border: 1px solid var(--grey-3);
}

.remove {
  color: var(--grey-3);
}

.remove:hover,
.remove:focus-visible {
  color: var(--color-red-primary);
}

.add {
  padding: 16px 18px;
  color: var(--grey-3);
}

.addIcon {
  width: 41px;
  height: 41px;
}

@media (max-width: 575px) {
  .root {
    padding: 15px 10px;
  } 

  .title {
    font-weight: 400;
  }

  .title label {
    justify-content: space-between;
  }

  .subtitle {
    margin-top: 18px;
    color: var(--grey-2);
  }

  .buttons {
    justify-content: center;
  }
  
  .add {
    min-height: 44px;
    margin: 0 auto;
    padding: 6px;
  }

  .addIcon {
    width: 32px;
    height: 32px;
  }
}
