.root {
  text-align: center;
}

.title {
  margin-bottom: 30px;
  font-size: 0.875rem;
}

.video {
  max-width: 672px;
  margin: 0 auto;
}

.buttons {
  margin-bottom: 30px;
}
