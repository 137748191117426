.content {
  text-align: center;
}

.description {
  max-width: 661px;
  font-size: 1.125rem;
}

.image {
  margin-top: 24px;
}

.image img {
  max-width: 100%;
}

.buttons {
  justify-content: center;
  margin-top: 60px;
}

@media (min-width: 576px) {
  .button {
    max-width: 240px;
  }
}
