.link {
  color: var(--color-green-primary);
  text-decoration: underline;
  cursor: pointer;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
}

.field {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.field input {
  border-color: var(--grey-4);
  text-align: center;
}

.modalButtons {
  margin-top: auto;
  justify-content: center;
}

.modalButton {
  min-width: 240px;
}

@media (min-width: 1025px) {
  .modalContent {
    min-height: 260px;
  }
}