.form {
  margin-top: 20px;
}

.usersText {
  margin-bottom: 10px;
}

.usersText a {
  color: var(--primary-color);
  text-decoration: underline;
}

.usersText a:hover,
.usersText a:focus {
  text-decoration: underline;
}

.usersTextIcon {
  padding: 3px;
  vertical-align: middle;
  color: var(--grey-3);
  line-height: 1.2;
}

.accentText,
.accentText .usersTextIcon {
  color: var(--primary-color);
}

.moreText {
  margin-left: 4px;
}

.item {
  display: flex;
  align-items: center;
  gap: 24px;
}

.item:not(:last-child) {
  margin-bottom: 20px;
}

.buttonAdd {
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  color: var(--grey-3);
}

.buttonAddIcon {
  width: 42px;
  height: 42px;
}

.fields {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 24px;
}

.buttonInvite {
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;
}

.inviteRemove {
  flex-shrink: 0;
  margin-top: 29px;
}

.inviteRemoveIcon {
  padding: 10px 0;
  width: 20px;
  height: 40px;
}

.inputContainer {
  flex: 1;
}

.input:not(:placeholder-shown) {
  background: var(--color-white-primary);
}

.filled :global(.rc-select-selector) {
  background: var(--color-white-primary);
}

.inputContainer :global(.rc-select-open .rc-select-selector) {
  background: var(--color-white-primary);
}

.usersText:global(.skeleton-loader) {
  min-height: 25px;
  width: 200px;
  border-radius: var(--border-radius-s);
}

.usersText:nth-child(2):global(.skeleton-loader) {
  width: 500px;
  max-width: 100%;
}

.select:global(.skeleton-loader) {
  height: 60px;
  border-radius: var(--border-radius-l);
}

@media (max-width: 800px) {
  .item {
    align-items: flex-start;
    gap: 8px;
  }

  .fields {
    flex-direction: column;
    gap: 16px;
  }

  .inputContainer {
    width: 100%;
  }

  .inviteRemove {
    padding: 0;
  }

  .inviteRemoveIcon {
    padding: 20px 5px;
    width: 30px;
    height: 60px;
  }
}

@media (max-width: 575px) {
  .fieldsRow {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
