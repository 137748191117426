.item {
  margin-bottom: 16px;
}

.itemRow {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  background: var(--color-white-primary);
  border: 1px solid var(--grey-3);
  border-radius: 10px;
  cursor: pointer;
  transition: all var(--transition);
  gap: 20px;
}

.itemRow:hover,
.itemRow:focus-visible {
  background: var(--grey-7);
}


.itemRow.selected {
  border-color: var(--primary-color);
  background: var(--color-green-4);
}

.itemRow.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.name {
  line-height: 1.25;
}

.description {
  margin-top: 8px;
  font-size: 12px;
  line-height: 1.17;
  color: var(--grey-0);
}

.fields {
  margin-top: 16px;
}

.fields :global(.forma-ui__fill-group__root) {
  padding: 0;
}

.label {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 15px;
  min-height: 47px;
}

.icons {
  display: flex;
  margin-left: auto;
  gap: 15px;
}

.fields :global([name="phone"]) {
  padding-left: 36px;
}

@media (max-width: 1024px) {
  .icons {
    display: none;
  }
}

@media (max-width: 575px) {
  .itemRow {
    padding: 8px 14px;
    gap: 10px;
  }
}
