.root {
  flex: 1;
  position: relative;
  background: var(--color-white-primary);
  color: var(--color-black-primary);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-m);
  font-size: 1rem;
  cursor: pointer;
  overflow: hidden;
}

.root:hover,
.root:focus-visible {
  background: var(--color-green-4);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  text-align: center;
}

.content {
  margin-top: 15px;
}

.title {
  font-weight: 600;
  line-height: 1.25;
}

.description {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.25;
}

.label {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 13px;
  line-height: 1.2;
  width: 100%;
  padding: 7px;
  background: var(--color-green-4);
  color: var(--color-green-primary);
  text-align: center;
}

.icon {
  flex-shrink: 0;
}

@media (max-width: 575px) {
  .root {
    flex: 0 0 auto;
  }

  .container {
    flex-direction: row;
    height: auto;
  }

  .content {
    flex-grow: 1;
    margin-top: 0;
    margin-left: 10px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .label {
    position: static;
  }
}
