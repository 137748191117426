.root {
  display: flex;
  margin-bottom: 16px;
}

.fillButton {
  min-height: 0;
  align-items: flex-start;
  padding: 8px 14px;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--grey-5);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  gap: 8px;
}

.fillButton.active {
  color: var(--primary-color);
}

.fillButton > span {
  flex-grow: 1;
}

.filledContent {
  display: flex;
  justify-content: space-between;
}

.fillButtonIcon {
  width: 18px;
  height: 18px;
  margin: -1px 0;
}

.fillButtonUpd {
  min-width: 320px;
  text-align: right;
}

.fillButtonUpd > label {
  flex-direction: row-reverse;
  margin: -9px -15px -9px 0;
  padding: 9px 15px 9px 12px;
  font-size: 15px;
  gap: 14px;
  color: var(--color-black-primary);
}

@media (max-width: 1280px) {
  .fillButtonUpd {
    min-width: 230px;
  }
}

@media (max-width: 575px) {
  .root {
    margin-top: 0;
    padding: 0;
  }

  .filledContent {
    flex-wrap: wrap;
  }

  .fillButtonUpd {
    width: 100%;
    text-align: center;
  }

  .fillButtonUpd > label {
    justify-content: center;
  }
}
