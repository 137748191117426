.root {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.button {
  min-height: 40px;
  padding: 7px 16px;
  font-size: 0.9375rem;
  font-weight: 500;
  border: 1px solid var(--grey-5);
  text-transform: uppercase;
}

.active,
.active:hover,
.active:focus-visible {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background: var(--color-green-4);
}
