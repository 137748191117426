.root {
  position: relative;
}

.button {
  margin: 0;
  padding: 0;
  color: var(--color-green-primary);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.25;
  border: none;
  box-shadow: none;
  background: none;
  outline: none;
}

.dropdown {
  width: 260px;
  border-radius: var(--border-radius-m);
  background: var(--color-white-primary);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}

.search {
  height: 48px;
  width: calc(100% - 10px);
  margin: 5px;
  border-radius: 16px;
  border: 1px solid var(--grey-6);
  text-align: left;
}

.search:focus {
  box-shadow: none;
  border-color: var(--grey-3);
}

.select {
  max-height: 190px;
  overflow-y: auto;
}

.item {
  margin-bottom: 3px;
  padding: 7px 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  cursor: pointer;
  outline: none;
}

.item:hover,
.item:focus-visible {
  background: var(--grey-7);
}

.icon {
  color: var(--grey-3);
}

.icon.active {
  color: var(--color-green-primary);
}

@media (max-width: 575px) {
  .dropdown {
    width: 100%;
    box-shadow: none;
  }

  .search {
    width: 100%;
    margin: 0 0 30px;
  }

  .button {
    margin-bottom: 17px;
  }

  .select {
    height: 55vh;
    height: 55dvh;
    position: static;
    max-height: unset;
    background: none;
    box-shadow: none;
  }

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 14px;
    border-radius: 12px;
    background: var(--grey-6);
    box-shadow: 0px 0px 2px 0px rgba(47, 58, 74, 0.02), 0px 3px 6px 0px rgba(105, 130, 166, 0.02);
    gap: 10px;
  }

  .item:hover,
  .item:focus-visible {
    background: var(--grey-6);
  }
}