.modal input {
  width: 100%;
  border-color: var(--grey-4);
}

.dropzone {
  padding: 18px;
  font-size: 14px;
}

.preview {
  display: flex;
  justify-content: center;
}

.previewItem {
  width: 165px;
}

.input {
  margin-bottom: 20px;
}

.uploaded {
  margin-top: 36px;
}

.uploadedTitle {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.uploadedItems {
  display: flex;
  gap: 14px;
  overflow-x: auto;
}

.uploadedItem {
  width: 112px;
  flex-shrink: 0;
  margin-bottom: 6px;
}

.signatureImageWrap {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-l);
  border: 1px solid var(--grey-3);
}

.signatureImageWrap.selected {
  border-color: var(--primary-color);
}

.signatureImage {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.signatureCheck {
  position: absolute;
  top: 7px;
  left: 6px;
  z-index: 1;
}

.signatureRemove {
  position: absolute;
  top: 7px;
  right: 5px;
  z-index: 1;
}

.signatureName {
  margin-top: 6px;
  font-size: 10px;
  line-height: 1.2;
}

.modalButton {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .modalButton {
    max-width: 240px;
  }

  .dropzone {
    height: 180px;
  }
}

@media (min-width: 801px) {
  .dropzone {
    height: 158px;
  }
}

@media (max-width: 575px) {
  .dropzone {
    height: 230px;
  }
}
