.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.buttons {
  flex-shrink: 0;
  margin-top: 20px;
  text-align: center;
}

.folders {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  overflow-y: auto;
}

.addButton {
  padding: 0;
  width: 74px;
  height: 74px;
  border-radius: var(--border-radius-l);
  background: var(--grey-7);
  color: var(--grey-2);
  box-shadow: none;
}

.addButton:hover {
  background: var(--color-white-primary);
}

.addButtonIcon {
  width: 36px;
  height: 36px;
}

.placeholderButton {
  height: 60px;
  border-radius: var(--border-radius-m);
}

@media (max-width: 800px) {
  .root {
    display: flex;
    flex-direction: column-reverse;
  }

  .buttons {
    margin: 46px 0 10px;
  }
}
