.root {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-white-primary);
  z-index: 1;
}

.list {
  display: flex;
  margin: 0;
  padding: 4px 0;
  list-style-type: none;
  gap: 4px;
}

.item {
  flex: 1;
  margin: 0;
  padding: 0;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  line-height: 1;
  gap: 2px;
}

.icon {
  line-height: 0;
  color: var(--grey-3);
}

.link.active {
  font-weight: 600;
  color: var(--primary-color);
}

.link.active .icon {
  color: inherit;
}