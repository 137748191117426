.root {
  display: flex;
}

.filters {
  flex-shrink: 0;
  width: 290px;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid var(--grey-3);
}

.content {
  flex-grow: 1;
  overflow: hidden;
}

.templatesButton {
  flex-direction: row-reverse;
  margin-bottom: 20px;
  font-size: 0.9375rem;
  line-height: 1.2;
  text-align: left;
}
