.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.root :global(.forma-ui__tabs__root) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.root :global(.forma-ui__tabs__header) {
  margin-bottom: 0;
  flex-shrink: 0;
}

.root :global(.forma-ui__tabs__header::after) {
  width: 100%;
  margin: 0;
  left: 0;
  right: 0;
}

.root :global(.forma-ui__tabs__navButton),
.root :global(.forma-ui__tabs__navButton:hover),
.root :global(.forma-ui__tabs__navButton:focus-visible) {
  background: var(--color-white-primary);
}

.root :global(.forma-ui__tabs__content) {
  background: var(--body-bg);
  padding: 35px 25px 25px;
  border-radius: 0 0 var(--border-radius-m)  var(--border-radius-m);
}

.root :global(.fill-document-content) {
  overflow-y: auto;
}

.root.sign :global(.fill-document-content > *) {
  pointer-events: none;
}

.title :global(.forma-ui__document-name__root) {
  padding: 0;
}

.filling {
  padding: 10px 0;
  overflow: hidden;
}

.fullWidth {
  width: 100%;
}

.info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px 20px;
}

.infoItem {
  font-size: 0.875rem;
  line-height: 1.1;
}

.infoLabel {
  margin-bottom: 5px;
  font-weight: 500;
}

.infoValue {
  font-weight: 300;
}

.infoValueLoading {
  width: 111px;
  height: 15.4px;
  border-radius: var(--border-radius-xs);
}

.buttons {
  margin-top: 0;
  margin-bottom: 30px;
  gap: 10px;
}

.button {
  flex-direction: row-reverse;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 0.875rem;
  gap: 10px;
}

@media (max-width: 575px) {
  .root :global(.forma-ui__tabs__content) {
    background: var(--body-bg);
    padding: 25px 15px 15px;
  }
}
