.root {
  position: relative;
  width: 100%;
  background: var(--grey-4);
  border-radius: var(--border-radius-l);
  line-height: 0;
  overflow: hidden;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  transform: translate(-50%, -50%) scale(1);
  transition: transform var(--transition);
  z-index: 1;
}

.playButton:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.video {
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background: var(--grey-6);
}
