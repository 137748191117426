.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow-a);
  background: var(--color-white-primary);
  padding: 5.5px 12px;
  gap: 4px;
  cursor: pointer;
}

.text {
  flex-grow: 1;
  margin-right: 8px;
  font-size: 0.812rem;
  padding: 3px 0;
  line-height: 1;
}

.input {
  position: absolute;
  top: 7px;
  right: 12px;
  width: 29px;
  height: 15px;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.icon {
  flex-shrink: 0;
  position: relative;
  display: block;
  margin: 2px 0;
  width: 29px;
  height: 15px;
  background: var(--grey-7);
  border-radius: var(--border-radius-xxs);
  transition: background var(--transition);
  z-index: 1;
}

.icon::after {
  content: '';
  position: absolute;
  inset: 2px auto 2px 2px;
  width: 11px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-xxs);
  transition: left var(--transition), right var(--transition);
}

.input:checked + .icon {
  background: var(--color-green-primary);
}

.input:checked + .icon::after {
  left: auto;
  right: 2px;
}

.edit {
  margin-top: -1px;
  min-height: 0;
}

.editIcon {
  width: 20px;
  height: 20px;
}

@media (max-width: 800px) {
  .edit {
    padding: 0;
    min-height: 0;
  }

  .text {
    padding: 4px 0;
  }
}
