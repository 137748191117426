.emptyDescription {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.link:hover,
.link:focus-visible {
  text-decoration: none;
}

.flex {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.flex ul {
  margin-top: 6px;
  padding-inline-start: 1.2rem;
}

@media (max-width: 575px) {
  .flex {
    flex-direction: column-reverse;
    gap: 16px;
  }

  .flex img {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
}