.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 306px;
  padding: 24px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  text-align: center;
}

.image {
  width: 100px;
  margin-bottom: 10px;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.1;
  color: var(--primary-color);
}

.buttons {
  margin-top: 24px;
}

.button {
  font-weight: 500;
}
