.root {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin-bottom: 38px;
  gap: 12px;
}

.search {
  flex-grow: 1;
}

.root button,
.root a {
  padding-top: 16px;
  padding-bottom: 16px;
}

.sortOption {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select {
  width: 205px;
}

.select :global(.rc-select-selector) {
  font-weight: 500;
}

@media (max-width: 800px) {
  .root {
    flex-wrap: wrap;
  }

  .root button,
  .root a,
  .select {
    flex: 1;
  }

  .root > div {
    flex-grow: 1;
  }

  .search {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .root button,
  .root a {
    width: 100%;
  }

  .select {
    width: 100%;
    margin-left: 0;
  }
}
