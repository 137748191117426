.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  text-align: center;
}

.buttons {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 28px;
}

.buttons button {
  white-space: nowrap;
}
