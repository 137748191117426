.root {
  display: grid;
  grid-template-columns: 1fr;
  background: var(--grey-6);
  border-radius: var(--border-radius-m);
  gap: 15px;
}

.item {
  overflow: hidden;
}
