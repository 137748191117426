.root {
  display: flex;
  justify-content: center;
  text-align: center;
}

.title {
  margin-bottom: 16px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.02;
}

.content {
  display: flex;
  align-items: flex-end;
  width: 412px;
  padding-bottom: 20px;
  gap: 8px;
}

.label {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28;
}

.applied {
  padding: 8px 14px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--color-green-primary);
  background: var(--color-green-4);
  border-radius: var(--border-radius-xxl);
}

@media (max-width: 800px) {
  .content {
    width: 100%;
  }
}