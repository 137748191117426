.root {
  max-width: 320px;
  margin: 230px auto;
}

.message {
  font-size: 1.125rem;
  line-height: 1.1;
  font-weight: 500;
  font-style: italic;
}

.author {
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 8px;
}

.photo {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border: 2px solid var(--color-white-primary);
  border-radius: var(--border-radius-xxl);
}

.name {
  margin-bottom: 4px;
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 600;
}

.position {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--grey-1);
}
