.root {
  position: relative;
  height: 100%;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  text-align: center;
  user-select: none;
  transition: box-shadow var(--transition) .1s;
}

.root:hover,
.root.hover,
.root.menuOpen {
  box-shadow: var(--box-shadow-b);
}

.root.updated,
.root.active {
  box-shadow: var(--box-shadow-green);
}

.root.selected {
  background: var(--color-green-4);
}

.container {
  height: 100%;
  padding: 18px 20px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  pointer-events: none;
}

.icon {
  height: 100%;
}

.fave {
  position: absolute;
  top: 0;
  right: 32px;
  box-sizing: border-box;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 2.1rem;
  font-size: 0.875rem;
  line-height: 1.21;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.optionsButton {
  position: absolute;
  bottom: 5px;
  right: 7px;
  line-height: 0;
}
