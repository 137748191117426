.container {
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-xs);
  background: var(--color-white-primary);
  overflow: hidden;
}

.addButton {
  min-height: 36px;
  border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
  background: var(--grey-7);
  color: var(--grey-2);
} 

.addButton.active {
  background: var(--color-green-4);
  color: var(--color-green-primary);
}

.imageButton {
  min-height: 36px;
  padding: 8px;
  font-size: 0.875rem;
  line-height: 1.42;
}

.buttons {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  transform: translateY(110%);
  transition: transform var(--transition);
  gap: 5px;
}

.container.hover .buttons {
  transform: translateY(0);
}

.container.hover .buttons::before {
  content: "";
  position: absolute;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  background: linear-gradient(rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0));
  z-index: -1;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 140%;
  overflow: hidden;
}

.image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.noImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.name {
  min-height: 36px;
  margin-top: 10px;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
}

.name:global(.skeleton-loader) {
  border-radius: var(--border-radius-s);
}

.modalTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  padding: 0 15px 0 0;
  font-size: 1.125rem;
  font-weight: 500;
  gap: 20px;
}

.modalImage {
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-m);
  overflow: hidden;
}

.modalImage:not(:last-child) {
  margin-bottom: 20px;
}

.informationBlock {
  display: flex;
  flex-direction: column;
  gap:8px;
  margin-bottom: 120px;
  padding: 10px;
  width: 100%;
}

.informationBlock > div:nth-child(1) {
  font-size: 0.68rem;
  font-weight: 400;
  color: var(--grey-1);
}

.informationBlock > div {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--color-black-primary);
}

.informationBlock > div:last-child {
  font-weight: 400;
  color: var(--grey-1);
}

.aboutPersonBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.aboutPersonBlock > div {
  color: var(--color-black-primary);
  flex-grow: 1;
  flex-shrink: 0;
}

.aboutPersonBlock > div > div:nth-child(1) {
  font-size: 1rem;
  font-weight: 600;
}

.aboutPersonBlock > div > div:nth-child(2) {
  font-size: 0.75rem;;
  font-weight: 400;
}

.imagePhoto {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden; 
}

.aboutPersonBlock > img {
  flex-grow: 0;
  flex-shrink: 0;
}

.mobileButtons {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  transition: transform var(--transition);
  gap: 5px;
  z-index: 999;
  padding: 15px;
}

.buttonsForMobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.show {
  opacity: 1;
  visibility: visible;
  background-color: var(--color-white-primary);
  border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
  box-shadow: 1px 1px 2px 2px var(--grey-4);
}

.show > h3 {
  font-size: 1rem;
  font-weight: 600;
  padding: 10px;
  position: relative;
  text-align: center;
}

.closeBlock {
  display: block;
  width: 35px;
  height: 4px;
  border-radius: 15px;
  background-color: var(--grey-4);
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1366px) {
  .informationBlock {
    margin-bottom: 60px;
  }
}

@media (max-width: 1280px) {
  .informationBlock {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .addButton {
    line-height: 1.2;
    font-size: 0.9375rem;
  }
}