.title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.32;
}

.title:global(.skeleton-loader) {
  width: 280px;
  border-radius: var(--border-radius-s);
}

.filters {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 25px;
  gap: 12px;
}

.categories {
  flex-grow: 1;
}

.headButtons {
  margin-top: 0;
  margin-bottom: 15px;
}

.headButton {
  flex-direction: row-reverse;
  text-decoration: underline;
  gap: 5px;
}

.headButton:hover,
.headButton:focus {
  text-decoration: underline;
}

.faqButton {
  margin-left: auto;
}

.tag {
  background: var(--color-white-primary);
  color: var(--color-black-primary);
  transition: var(--transition);
}

.tag:hover,
.tag:focus-visible {
  background: var(--grey-6);
}

.tag.selected {
  background: var(--primary-color);
  color: var(--color-white-primary);
}

@media (max-width: 575px) {
  .filters {
    flex-direction: column;
    align-items: center;
  }
}
