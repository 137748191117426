.slider {
  overflow: hidden;
  user-select: none;
}

.slider__container {
  display: flex;
}

.slider__container > * {
  flex: 0 0 100%;
  min-width: 0;
}

.slider__dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.slider__dot {
  display: inline-block;
  margin-top: 20px;
  width: 7px;
  height: 7px;
  padding: 0;
  background: var(--grey-4);
  border-radius: 10px;
  cursor: pointer;
}

.slider__dot--active,
.slider__dot:hover {
  background: var(--primary-color);
}
