.root {
  display: flex;
  align-items: center;
  padding: 16px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  gap: 10px;
}

.button {
  min-height: 46px;
  padding: 8px 14px;
  font-weight: 500;
  border: 1px solid var(--grey-3);
}

.buttons {
  flex-shrink: 0;
  display: flex;
  margin-left: auto;
  gap: 15px;
}

.status {
  font-size: 14px;
}

.status.error {
  color: var(--error-color);
}

@media (max-width: 1024px) {
  .root {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .buttons {
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .buttons {
    margin-top: 10px;
    gap: 8px;
    flex-direction: column;
    width: 100%;
  }
}
