.fields {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.colors {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  gap: 10px;
}

.color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: var(--border-radius-xxl);
  border: 9px solid var(--grey-2);
  cursor: pointer;
}

.color.selected {
  box-shadow: 0 0 0 1px var(--color-black-primary);
}

.buttons {
  margin-top: 0;
}

.button {
  min-height: 36px;
  padding: 6px 10px;
  font-size: 14px;
  gap: 6px;
}

.saveButton {
  min-width: 132px;
}
