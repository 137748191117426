.root {
  margin-left: 5px;
}

.saveTableCell {
  text-align: center;
  width: 235px;
  word-wrap: break-word;
}

.wrapperSaveTableCell {
  padding: 9px 6px;
}

.nameVariable {
  display: flex;
  align-items: center;
  height: 3rem;
  text-align: center;
  overflow: hidden;
}

.nameVariable>div {
  display: -webkit-box;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 0.875rem;
  line-height: 1.14;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected {
  color: var(--color-green-primary);
}

.headerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 235px;
  gap: 7px;
}

.opacityRowPreLast {
  opacity: 0.7;
}

.opacityRowLast {
  opacity: 0.4;
}

.column {
  padding-right: 65px
}

.checkedIcon {
  line-height: 20px;
  color: var(--color-green-primary);
}

.noCheckedIcon {
  line-height: 20px;
  color: var(--grey-2);
}

.dropdownSwitches {
  display: grid;
  padding-top: 14px;
  padding-bottom: 14px;
  grid-template-columns: 1fr 1fr;
  gap: 8px 12px;
}

@media (max-width: 1024px) {

  .root :global(.forma-ui__table__head),
  .root :global(.forma-ui__table__row) {
    display: table-row;
  }

  .root :global(.forma-ui__table__cell) {
    width: auto;
  }
}
