.inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.inputContainer {
  flex: 0 0 46px;
  width: 46px;
}

.inputContainer .input {
  height: 56px;
  padding-left: 5px;
  padding-right: 5px;
  background: var(--color-white-primary);
  font-family: var(--headings-font);
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.divider {
  margin: 0 1px;
  width: 16px;
  height: 1px;
  background: var(--grey-3);
}

.errorText {
  margin-top: 20px;
  color: var(--color-red-primary);
  font-size: 0.875rem;
  line-height: 1.42;
  height: calc(0.875rem * 1.42 * 2);
}
