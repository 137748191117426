.block {
  position: relative;
  padding: 12px 32px 12px 16px;
  background: var(--grey-6);
  border-radius: var(--border-radius-l);
  overflow: auto;
}

.block.dragging {
  box-shadow: var(--box-shadow-a);
  cursor: move;
}

.block.dragging .handler {
  cursor: move;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
}

.title::before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
  border-radius: var(--border-radius-l) 0 0 var(--border-radius-l);
}

.nameInput {
  height: 48px;
  width: 100%;
  padding: 10px 0 10px 17px;
  background: none;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.14;
}

.nameInput:hover,
.nameInput:focus {
  outline: none;
}

.toggler {
  flex-shrink: 0;
  margin-left: auto;
  margin-right: 8px;
  transform: scaleY(-1);
  border-radius: var(--border-radius-m);
  transition: all var(--transition);
  cursor: pointer;
}

.toggler.toggler {
  padding: 7px;
}

.togglerIcon {
  width: 16px;
  height: 16px;
}

.toggler.open {
  transform: scaleY(1);
}

.delete {
  flex-direction: row-reverse;
  flex: 0 0 auto;
  margin-right: 6px;
  font-weight: 400;
  gap: 5px;
}

.delete:hover,
.delete:focus-visible {
  color: var(--color-red-primary);
}

.add {
  margin-top: 15px;
}

.addItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.addButton {
  flex: 0 0 auto;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  border-radius: var(--border-radius-xxl);
  background: transparent;
  color: var(--grey-0);
  padding: 8px 0;
  gap: 5px;
}

.addCount {
  margin-top: 2px;
  font-size: 0.875rem;
  color: var(--grey-2);
}

.fullScreen .header .add {
  display: flex;
  margin-top: 0;
  gap: 15px;
}

.vars {
  margin-top: 15px;
  margin-bottom: 12px;
}

.varsTitle {
  margin-bottom: 10px;
  font-size: 0.875rem;
  color: var(--grey-2);
}

.placeholder {
  height: 278px;
  border-radius: var(--border-radius-l);
}

.iconHidden.iconHidden {
  display: none;
}

.handler {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 16px;
  padding: 13px 4px;
  text-align: center;
  background: var(--grey-5);
  color: var(--grey-3);
  border-radius: 0 var(--border-radius-l) var(--border-radius-l) 0;
  transition: background var(--transition), color var(--transition);
  cursor: pointer;
}

.block.dragging .handler,
.handler:hover {
  background: var(--color-green-3);
  color: var(--color-green-primary);
}

@media (max-width: 1280px) {
  .delete {
    margin-right: 0;
  }
}
