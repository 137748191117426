.description {
  font-size: 14px;
  text-align: center;
}

.button.button {
  min-height: 46px;
  font-size: 15px;
  font-weight: 500;
  padding: 8px 16px;
}