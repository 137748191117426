.root {
  max-width: 395px;
  margin: 85px auto;
}

.image {
  width: 100%;
  padding: 10px;
  pointer-events: none;
}
