.content {
  position: relative;
}

.table table {
  table-layout: fixed;
}

.fio {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email {
  width: 32%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons {
  width: 60px;
  text-align: right;
}

.group {
  width: 28%;
  overflow: visible;
}

.selectContainer {
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: 12px;
}

.select {
  margin: -10px;
}

.select :global(.rc-select:not(.rc-select-open) .rc-select-selector) {
  background: none;
  border: 1px dashed var(--grey-3);
}

.select :global(.rc-select:not(.rc-select-open) .rc-select-selector:hover) {
  background: var(--grey-6);
  border: 1px solid var(--grey-6);
}

.select :global(.rc-select-selector) {
  height: 53px;
  font-size: 0.9375rem;
  font-weight: 500;
}

.select :global(.rc-select-selection-placeholder) {
  color: var(--color-black-primary);
}

.remove {
  color: var(--grey-3);
}

.remove:hover {
  color: var(--error-color);
}

@media (max-width: 575px) {
  .select {
    margin: 6px 0 0;
  }
}