.head {
  display: flex;
  margin-bottom: 25px;
  margin-top: -20px;
  gap: 16px;
}

.headButton {
  padding-left: 12px;
  text-decoration: underline;
  font-weight: 400;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 22px;
}

.buttons {
  align-items: flex-start;
  justify-content: center;
  margin: 30px auto 0;
}

.button {
  min-width: 240px;
  gap: 10px;
}

.buttonMulti {
  width: 245px;
}

.bage.error {
  color: var(--error-color);
}

.bage.success {
  color: var(--success-color);
}

.variations + .fillingFields {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid var(--grey-3);
}

.notice {
  display: flex;
  align-items: center;
  margin: 35px 0;
  color: var(--grey-1);
  text-align: center;
  gap: 10px;
}

.noticeContent {
  flex-grow: 1;
}

.noticeButtons {
  flex-shrink: 0;
  width: 25%;
}

.noticeButtons:first-child {
  text-align: left;
}
.noticeButtons:last-child {
  text-align: right;
}

.noticeButton {
  position: relative;
}

.noticeBage {
  position: absolute;
  top: -10px;
  right: 9px;
  min-width: 46px;
  border-radius: var(--border-radius-s);
  font-size: .75rem;
  line-height: 1.67;
  font-weight: 400;
  color: var(--color-white-primary);
}

.noticeBage.error {
  background: var(--error-color);
}

.noticeBage.success {
  background: var(--success-color);
}

.emptyFields {
  margin-bottom: 5px;
  color: var(--error-color);
  font-size: 1.125rem;
}

.documents {
  margin-top: 40px;
  padding: 0 12px;
}

.sendingTitle {
  margin-top: 2px;
  margin-left: auto;
  font-size: 1.125rem;
  text-align: right;
}

@media (max-width: 800px) {
  .notice {
    display: none;
  }
}

@media (max-width: 575px) {
  .head {
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
  }

  .sendingTitle {
    margin: 0;
  }

  .buttons.mobileInline {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .button,
  .buttonMulti {
    min-width: 0;
    width: 100%;
  }

  .documents {
    padding-left: 0;
    padding-right: 0;
  }
}
