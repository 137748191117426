.item {
  height: 165px;
  overflow: visible;
  cursor: pointer;
}

.draggable {
  width: 100%;
  cursor: grabbing;
}

.group {
  position: relative;
  margin: 0 -28px;
  padding: 14px 28px;
  background: var(--color-green-3);
  border-radius: var(--border-radius-l);
  grid-column: span 5;
}

.group.group {
  width: calc(100% + 56px);
}

.groupItems {
  justify-content: center;
}

.dropContainer {
  flex-grow: 1;
  height: 100%;
}

.groupLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 53px 0;
  color: var(--color-white-primary);
}

.showAll {
  position: relative;
  margin-top: 16px;
  text-align: center;
}

.showAllLink {
  font-size: 1rem;
  font-weight: 500;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.modalMove {
  width: 500px;
}

.loader {
  padding: 16px;
  color: var(--color-white-primary);
}

@media (max-width: 1366px) {
  .group {
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .group.group {
    width: calc(100% + 16px);
  }
}

@media (max-width: 1024px) {
  .noItems,
  .group {
    grid-column: span 4;
  }
}

@media (max-width: 800px) {
  .noItems,
  .group {
    grid-column: span 3;
  }
}

@media (max-width: 575px) {
  .noItems,
  .group {
    grid-column: span 2;
  }
}
