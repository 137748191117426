.root {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin-bottom: 38px;
  gap: 12px;
}

.search {
  flex-grow: 1;
}

.button {
  width: 100%;
  max-width: 230px;
  padding: 16px 10px;
}

.select :global(.rc-select-selector) {
  font-weight: 500;
}

.select :global(.rc-select-selection-placeholder) {
  color: var(--color-black-primary);
}

.selectPeriod :global(.rc-select-selector) {
  padding-right: 16px;
}

.selectCategories {
  width: 100%;
  max-width: 200px;
}

.selectIcon {
  display: inline-block;
  height: 24px;
  line-height: 24px;
}

.selectIcon svg {
  width: 24px;
  height: 24px;
}

.selectIcon.active {
  color: var(--primary-color);
}

.label {
  display: inline-block;
  padding: 3px 10px;
  border-radius: var(--border-radius-l);
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .root {
    flex-wrap: wrap;
  }

  .search {
    width: 100%;
  }

  .button,
  .select {
    flex: 1;
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .root button,
  .root a {
    width: 100%;
  }

  .select {
    margin-left: 0;
    max-width: 100%;
  }
}
