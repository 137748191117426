.title {
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.1;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.item {
  margin: 0 0 10px;
  padding: 0;
}

.item:global(.skeleton-loader) {
  min-height: 44px;
  border-radius: var(--border-radius-s);
}

.withDivider {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--grey-3);
}

.link {
  min-height: 44px;
  color: var(--color-black-primary);
  font-weight: 500;
  line-height: 1.1;
  text-align: left;
}

.link:hover,
.link:focus-visible,
.active {
  color: var(--primary-color);
}

.linkIcon {
  width: 34px;
  height: 34px;
}
