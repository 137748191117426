.root {
  position: relative;
}

.cellSku {
  width: 13%;
}

.cellName {
  width: 38%;
}

.cellUnit {
  width: 12%;
}

.cellPrice {
  width: 13%;
}

.cellCategory {
  width: 22%;
}

.cellButtons {
  width: 50px;
  min-width: 50px;
  line-height: 0;
}
