.link {
  display: flex;
  align-items: center;
  gap: 13px;
  outline: none;
}

.link:hover .image {
  opacity: 0;
  visibility: hidden;
}

.link:hover .imageHover {
  opacity: 1;
  visibility: visible;
}

.imageWrap,
.imageText {
  flex-shrink: 0;
  line-height: 0;
}

.imageWrap {
  position: relative;
  height: 37px;
  width: 37px;
  line-height: 0;
}

.image,
.imageHover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imageHover svg {
  width: 100%;
  height: 100%;
}

.imageHover {
  opacity: 0;
  visibility: hidden;
}

.imageText {
  width: 102px;
}

.imageText svg {
  width: 100%;
}

.button {
  min-width: 211px;
  padding: 12px 16px;
  border-radius: var(--border-radius-l);
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-a);
}

.button:focus-visible {
  outline: none;
}

@media (max-width: 575px) {
  .link {
    gap: 9px;
  }

  .button {
    min-width: 160px;
    padding: 10px 12px;
  }

  .imageWrap {
    height: 28px;
    width: 28px;
  }

  .imageText {
    width: 78px;
  }
}