.inputs {
  display: flex;
  gap: 24px;
}

@media (min-width: 576px) {
  .button {
    min-width: 240px;
  }
  
  .modalButton {
    flex-basis: 240px;
    width: 240px;
  }
}

@media (max-width: 575px) {
  .inputs {
    flex-direction: column;
    gap: 10px;
  }
}
