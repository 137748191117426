.root {
  position: relative;
  height: 100%;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.container {
  height: 100%;
  padding: 6px 20px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  pointer-events: none;
}

.icon {
  height: 100%;
}

.remove {
  position: absolute;
  top: 8px;
  right: 13px;
  box-sizing: border-box;
}

.removeIcon {
  width: 16px;
  height: 16px;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 2.1rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.21;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folderName {
  width: 100%;
  max-height: 2.1rem;
  font-size: 0.875rem;
  line-height: 1.21;
  color: var(--grey-3);
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  margin: 2px;
  color: var(--grey-3);
  line-height: 0;
}

.placeholder {
  height: 100%;
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-b);
}
