.modal {
  min-height: 460px;
}

.modal > :global(.modal__content) {
  flex-grow: 1;
}

.modalContent {
  display: flex;
  height: 100%;
  gap: 20px;
}

.modalButtons {
  margin-top: 25px;
  justify-content: space-between;
}

.modalButtons > * {
  flex: 0 0 240px;
}

.modalButtons > .requestButton {
  flex: 0 0 auto;
  width: auto;
}

.smallButton {
  margin-top: 10px;
  font-size: 0.875rem;
}

.dropzoneWrap {
  width: 100%;
}

.dropzone {
  height: 197px;
  margin-bottom: 10px;
}

.dropzoneLarge {
  height: 100%;
}

.successModal {
  min-height: 315px;
  text-align: center;
}

.successTitle {
  margin-top: 22px;
  font-size: 1.75rem;
  font-weight: 600;
}

.successDescr {
  max-width: 478px;
  margin: 22px auto 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.1;
}

.successButtons {
  margin-top: 36px;
}

.successButton {
  max-width: 240px;
  margin: 0 auto;
}

.successImage {
  width: 100%;
}

.accent {
  padding: 0 5px;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius-xs);
}

@media (min-width: 801px) {
  .successImage {
    height: 146px;
  }
}

@media (min-width: 1025px) {
  .successImage {
    height: 170px;
  }
}

@media (max-width: 575px) {
  .modal {
    min-height: 0;
  }

  .modalContent {
    flex-direction: column;
  }

  .modalButtons > * {
    flex-basis: auto;
  }

  .accent {
    padding: 0;
    border: none;
  }
}
