.slider {
  overflow: hidden;
}

.slider__container {
  display: flex;
}

.slider__container > * {
  flex: 0 0 100%;
  min-width: 0;
}
