.root {
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow-d);
  overflow: hidden;
}

.main {
  display: flex;
}

.button {
  border-radius: 0;
  text-align: left;
  justify-content: flex-end;
  gap: 10px;
}

.button.loading {
  justify-content: center;
}

.buttonIcon {
  margin: -5px -5px -5px 0;
  width: 34px;
  height: 34px;
}

.dropdownButton {
  padding-left: 16px;
  padding-right: 16px;
  border-left: 1px solid var(--grey-3);
  border-radius: 0;
  color: var(--grey-3);
}

.dropdownButtonIcon {
  width: 12px;
  height: 12px;
}

.open .dropdownButton {
  display: none;
}

/* .open .main .button {
  margin-right: 45px;
} */

.open {
  background: var(--color-white-primary);
  box-shadow: 0 0 0 1px var(--grey-3), var(--box-shadow-a);
}

@media (max-width: 575px) {
  .button {
    text-align: center;
    justify-content: center;
  }
}
