.item {
  padding: 20px;
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-l);
}

.item:not(:last-child) {
  margin-bottom: 10px;
}

.icon {
  
}