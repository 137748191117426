.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 890px;
  margin: 0 auto;
  gap: 20px;
}

.column {
  margin-bottom: 20px;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.renameModal input {
  border-color: var(--grey-4);
}

.buttons {
  text-align: center;
}

@media (max-width: 575px) {
  .row {
    grid-template-columns: 1fr;
  }
}
