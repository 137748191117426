.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 54px;
  padding: 12px 20px 11px calc(var(--menu-width) + 24px);
  background: var(--body-bg);
  border-bottom: 1px solid var(--grey-3);
  gap: 10px;
  z-index: 910;
}

.contentContainer {
  display: flex;
  flex-grow: 1;
}

.footer {
  position: sticky;
  bottom: 0;
  z-index: 910;
}

.menu::after {
  content: '';
  display: block;
  width: var(--menu-width);
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 5px 10px;
}

.breadcrumbs .link {
  font-size: 0.875rem;
  text-decoration: underline;
  font-weight: 400;
  text-align: right;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-grow: 1;
}

.content.content {
  width: calc(100% - var(--menu-width));
}

.buttonIcon {
  width: 30px;
  height: 30px;
}

.title {
  display: flex;
  align-items: center;
  margin-right: auto;
  font-family: var(--headings-font);
  font-size: 0.9375rem;
  font-weight: 700;
  gap: 20px;
}

.langButton {
  width: 42px;
  height: 42px;
  padding: 4px;
  border-radius: var(--border-radius-xs);
}

.buttons {
  flex-shrink: 0;
  display: flex;
  gap: 10px;
}

.buttonOutline {
  background: none;
  padding: 3px 20px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 0.875rem;
  gap: 5px;
}

.buttonOutline:hover,
.buttonOutline:focus-visible {
  background: var(--color-green-4);
  box-shadow: var(--box-shadow-a);
  color: var(--primary-color);
}

.question {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 910;
}

.scrollToTop :global(.forma-ui__scroll-to-top-button__root) {
  right: 20px;
  bottom: 85px;
  z-index: 910;
}

.scrollToTop :global(.forma-ui__scroll-to-top-button__button) {
  padding: 13px;
  min-height: 0;
}

@media (max-width: 1280px) {
  .container {
    margin-left: 0;
    margin-right: 0;
    padding-left: calc(var(--menu-width) + var(--container-padding));
  }
}

@media (max-width: 800px) {
  .breadcrumbs {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .header {
    flex-wrap: nowrap;
    min-height: 46px;
    gap: 0;
  }

  .header.header {
    padding: 0 var(--container-padding);
  }

  .buttons {
    display: none;
  }

  .menu {
    line-height: 0;
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
  }

  .backButton {
    padding: 10px 10px 10px 0;
  }

  .backButton .backButtonIcon {
    color: var(--color-black-primary);
  }

  .content {
    margin-top: 20px;
    margin-bottom: 80px;
  }

  .question {
    bottom: 60px;
  }

  .scrollToTop :global(.forma-ui__scroll-to-top-button__root) {
    right: 23px;
    bottom: 120px;
  }

  .scrollToTop :global(.forma-ui__scroll-to-top-button__button) {
    padding: 10px;
  }
}
