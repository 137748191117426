.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  gap: 4px;
}

.root .button {
  padding: 12px;
}

.button.active {
  color: var(--primary-color);
  background: var(--grey-7);
}

@media (max-width: 575px) {
  .root {
    padding: 3px;
  }

  .root .button {
    padding: 10px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}