.container {
  padding: 30px;
  border: 1px solid var(--color-green-primary);
  border-radius: var(--border-radius-l);
  background: var(--body-bg);
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 22px;
}

.buttons {
  justify-content: flex-end;
}

.checkedIcon {
  color: var(--color-green-primary);
  line-height: 20px;
}

@media (max-width: 800px) {
  .items {
    flex-direction: column;
  }
}
