.wrapper {
  position: relative;
  height: 100%;
}

.border {
  position: absolute;
  inset: 0;
  border: 2px dashed var(--grey-4);
  border-radius: var(--border-radius-l);
  transition: top var(--transition);
  box-sizing: border-box;
}

.container {
  height: 100%;
}

.children {
  position: relative;
  top: 0;
  height: 100%;
  user-select: none;
  transition: top var(--transition) .1s;
}

.children::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.children.menuOpen,
.children:hover {
  top: -12px;
}

.children.menuOpen::before,
.children:hover::before {
  top: 12px;
}

.children.updated {
  animation: show 0.6s ease 0.6s;
  animation-iteration-count: 2;
}

/* :global(.onboarding-show) .children {
  top: 0;
} */

@keyframes show {
  0% {
    top: 0;
  }

  50% {
    top: -12px;
  }

  100% {
    top: 0;
  }
}
