.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--menu-width);
  background: var(--body-bg);
  border-right: 1px solid var(--grey-3);
  z-index: 915;
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
}

.list::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}

.list::-webkit-scrollbar-track {
  background: transparent;
}

.item {
  padding: 0;
}

.item.subscribe {
  border-top: 1px solid var(--grey-3);
  margin-top: auto;
}

.homepage {
  border-bottom: 1px solid var(--grey-3);
}

.homepage .link {
  color: var(--color-black-primary);
}

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  text-align: center;
  color: var(--grey-2);
  transition: color var(--transition);
  gap: 5px;
}

.link:hover,
.link:focus-visible {
  background: var(--grey-7);
}

.link.active {
  color: var(--primary-color);
  background: var(--color-green-4);
}

.link.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.icon {
  flex-shrink: 0;
  line-height: 0;
  width: 30px;
  height: 30px;
}

.logo {
  flex-shrink: 0;
  justify-content: center;
  padding: 5px;
  background: var(--primary-color);
}

.logo > div {
  height: 44px;
  width: 44px;
}

.modal {
  text-align: center;
  font-size: 14px;
}

@media (max-width: 575px) {
  .root {
    left: -100px;
  }
}
