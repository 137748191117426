.modalSelect {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 15px;
}

.modalButtons {
  margin-top: 17px;
}

.modalSwitcher > label {
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-weight: 500;
}

.types {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.typesButton {
  justify-content: flex-start;
  padding: 6px 14px;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
}

.addOptionButton {
  padding: 3px;
  background: var(--color-green-4);
  color: var(--color-green-primary);
  box-shadow: var(--box-shadow-a);
}

.addOptionButton:hover,
.addOptionButton:focus-visible {
  background: var(--color-green-4);
  color: var(--color-green-primary);
}

.textareaLabel {
  margin-top: -35px;
}

@media (max-width: 800px) {
  .typesButton {
    min-height: 0;
  }
}