.root {
  display: flex;
}

.root.fixed {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 415px;
  background: var(--body-bg);
  border: 1px solid var(--grey-3);
  border-top-left-radius: var(--border-radius-m);
  transform: translateX(385px);
  transition: transform var(--transition);
  z-index: 1;
}

.root.open {
  transform: translateX(0);
}

.opener {
  flex-shrink: 0;
  padding: 16px 2px;
  color: var(--grey-2);
  background: var(--grey-6);
  border-top-left-radius: var(--border-radius-m);
  cursor: pointer;
}

.open .openerIcon {
  transform: scaleX(-1);
}

.fixed .controls {
  padding: 10px;
  margin: 0;
}

.controls {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 10px;
  margin-right: -10px;
  overflow-y: auto;
  gap: 10px;
}

.controls > * {
  flex-shrink: 0;
}

.controlsButtons {
  margin: 0 14px 36px;
}

.placeholderButton {
  margin-bottom: 58px;
  height: 60px;
  border-radius: var(--border-radius-m);
}

.iconAdd {
  color: var(--primary-color);
}

.buttonAdd {
  max-width: 330px;
  justify-content: flex-start;
}

.fullScreenButton {
  flex-direction: row-reverse;
  margin-left: auto;
  font-weight: 500;
  gap: 10px;
}
