.list {
  display: grid;
  gap: 24px;
}

.list.grid-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.list.grid-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.list.grid-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (max-width: 1024px) {
  .list {
    gap: 20px;
  }

  .list.grid-5 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .list.grid-4 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .list.grid-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 800px) {
  .list {
    gap: 16px;
  }

  .list.grid-5 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .list.grid-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 800px) {
  .list {
    gap: 16px;
  }
}

@media (max-width: 575px) {
  .list {
    gap: 12px;
  }

  .list.grid-5 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
