.notice {
  display: block;
  margin-top: 5px;
  margin-bottom: calc(-0.875rem - 5px);
  color: var(--success-color);
  font-size: 0.875rem;
  line-height: 1;
}

@media (max-width: 575px) {
  .notice {
    font-size: 0.79rem;
    margin-bottom: calc(-0.79rem - 4px);
    margin-top: 4px;
  }
}
