.modal {
  display: flex;
  flex-direction: column;
  min-height: 480px;
}

.content {
  margin-bottom: auto;
}

.buttons {
  justify-content: flex-end;
}

@media (min-width: 801px) {
  .button {
    min-width: 240px;
  }
}
