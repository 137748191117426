.group {
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-m);
  background: var(--grey-6);
}

.group.draggable {
  padding-right: 26px;
}

.group.dragging {
  border-color: var(--primary-color);
  box-shadow: var(--box-shadow-a);
  cursor: move;
}

.group.dragging .handler {
  cursor: move;
}

.group.over {
  border-color: var(--primary-color);
}

.groupHead {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.groupTitle {
  flex: 1;
  font-size: 0.9375rem;
  font-weight: 500;
  color: var(--grey-1);
}

.groupButtons {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  gap: 10px;
}

.renameInput {
  margin: -4px 0;
  height: 32px;
  padding: 4px 12px;
  font-size: 15px;
  border-radius: var(--border-radius-m);
}

.renameSubmit {
  margin: -4px 0;
  min-height: 32px;
  padding: 7px;
  border-radius: var(--border-radius-m);
}

.renameSubmitIcon {
  width: 16px;
  height: 16px;
}

.buttons {
  margin-top: 10px;
}

.variableAdd {
  font-size: 0.875rem;
  font-weight: 500;
  gap: 10px;
}

.handler {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 16px;
  padding: 13px 4px;
  text-align: center;
  background: var(--grey-5);
  color: var(--grey-3);
  border-radius: 0 var(--border-radius-m) var(--border-radius-m) 0;
  transition: background var(--transition), color var(--transition);
  cursor: pointer;
}

.group.dragging .handler,
.handler:hover {
  background: var(--primary-color-bg);
  color: var(--primary-color);
}

.items {
  display: flex;
  flex-direction: column;
  gap: 9px 7px;
}

.items.inline {
  flex-flow: row wrap;
}

.item {
  display: flex;
  align-items: center;
  min-height: 30px;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 5px 15px;
  background: var(--color-white-primary);
  border: none;
  box-shadow: none;
  border-radius: var(--border-radius-l);
  gap: 10px;
  cursor: pointer;
}

.item:hover,
.item.active {
  background: var(--grey-7);
}

.item.disabled {
  color: var(--grey-2);
  cursor: default;
}

.item.disabled:hover {
  background: var(--color-white-primary);
}

.item.notUsed {
  background: var(--color-red-bg);
}

.item.dragging {
  cursor: move;
  box-shadow: var(--box-shadow-a);
}

.itemText {
  flex-grow: 1;
}

.itemHandler {
  line-height: 0;
  color: var(--grey-3);
}

.dropPlaceholder {
  font-size: 0.8125rem;
  color: var(--grey-2);
}

.edit,
.remove {
  font-weight: 400;
  flex-direction: row-reverse;
  color: var(--grey-3);
  gap: 5px;
}

.remove:hover,
.remove:focus-visible {
  color: var(--error-color);
}
