.list {
  min-width: 210px;
  max-width: 260px;
  max-height: 45vh;
  padding: 4px;
}

.item {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 8px 12px;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: var(--border-radius-s);
  text-align: left;
  cursor: pointer;
  word-wrap: break-word;
}

.item:hover,
.item:focus-visible,
.item.active {
  background: var(--grey-7);
}

.item:not(:last-child) {
  margin-bottom: 3px;
}

.item.disabled {
  pointer-events: none;
  color: var(--grey-2);
}

.icon {
  line-height: 0;
  margin: -4px -10px -5px auto;
}

.iconChecked {
  line-height: 0;
  color: var(--primary-color);
  margin-left: auto;
}
