.item > div {
  padding: 12px 90px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.42;
  text-align: center;
}

.item :global(.success) {
  background: var(--color-green-4);
}

.item :global(.attention) {
  background: #FFEE94;
}

.item :global(.error) {
  background: var(--color-red-bg);
}

@media (max-width: 575px) {
  .item > div {
    padding: 8px var(--container-padding);
    line-height: 1.2;
  }
}