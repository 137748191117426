.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 7px 19px;
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--grey-2);
  font-size: 0.875rem;
  line-height: 1.42;
}

.success {
  color: var(--primary-color);
}

.checkbox {
  line-height: 0;
}

@media (max-width: 575px) {
  .root {
    justify-content: flex-start;
    gap: 8px 10px;
  }

  .item {
    gap: 8px;
  }
}
